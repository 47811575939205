import React from "react"
import PropTypes from "prop-types"
import Image from "../Image/Image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link as GatsbyLink } from "gatsby"
import { gaEventClasses } from "../../../constants/gtm.constants"

const Link = props => {
    const {
        label,
        url: _url,
        href: _href,
        target,
        title,
        className = "",
        children,
        onClick,
        onFocus,
        onBlur,
        id,
        scrollToElementId,
        gaEventClass,
        gaEventLabel = "",
        image,
    } = props
    const url = _url || _href
    const regex = new RegExp(
        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
    )
    const isExternallink = regex.test(url)
    let pageUrl
    if (url) {
        pageUrl = !url?.trim()?.endsWith("/") && !(url?.trim()?.endsWith(".pdf") || isExternallink) ? `${url}/` : url
    }
    let href = pageUrl
    if (pageUrl && scrollToElementId) {
        href = `${pageUrl}#${scrollToElementId}`
    } else if (!pageUrl && scrollToElementId) {
        href = `#${scrollToElementId}`
    }

    const targetWindow = target || (isExternallink ? "_blank" : "_self")

    // if image is there, pass down event to image element
    const eventClassFinal = gaEventClass
        ? gaEventClass
        : isExternallink
        ? gaEventClasses.external_link
        : gaEventClasses.internal_link

    const classNameFinal = className ? [className, eventClassFinal].join(" ") : eventClassFinal
    const content = children || (
        <>
            {image ? (
                <Image contentfulImage={image} eventClass={gaEventClass} eventLabel={gaEventLabel} />
            ) : (
                label || null
            )}
        </>
    )

    if (!url && !scrollToElementId) {
        return <div className={classNameFinal}>{content}</div>
    }

    const scrollOnClick = scrollToElementId?.length > 0

    // if image is there, pass down event to image element
    const dataAction = !image ? gaEventLabel || href : ""

    if (isExternallink) {
        return (
            <a
                href={href}
                target={targetWindow}
                className={classNameFinal}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                data-action-detail={gaEventLabel || href}
                title={title}
                id={id}
            >
                {content || children}
            </a>
        )
    } else if (scrollOnClick) {
        return (
            <AnchorLink
                to={href}
                className={classNameFinal}
                title={title}
                onAnchorLinkClick={onClick}
                gatsbyLinkProps={{
                    "data-action-detail": gaEventLabel || label || href,
                    id: id,
                    target: targetWindow,
                    onFocus: onFocus,
                    onBlur: onBlur,
                }}
            >
                {content || children}
            </AnchorLink>
        )
    } else {
        return (
            <GatsbyLink
                to={href}
                target={targetWindow}
                className={classNameFinal}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={onClick}
                data-action-detail={gaEventLabel || label || href}
                title={title}
                id={id}
            >
                {content || children}
            </GatsbyLink>
        )
    }
}

Link.propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    scrollToElementId: PropTypes.string,
    image: PropTypes.object,
    id: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default Link
