export const defaultStyle = {
    aboutCard: "about-card flex relative justify-center items-center w-[49%] sm:w-full py-[35px] px-[48px]",
    aboutCardTitle:
        "about-card-title cursor-pointer max-w-[340px] z-[3] text-[52px] custom-line-height text-center underline text-fonziYellow font-HelveticaNeueBold uppercase",
    aboutCardText:
        "about-card-text sm:z-[5] custom-line-height left-1/2 transform -translate-x-1/2 translate-x-[-150px] w-[340px] font-HelveticaNeueRegular text-white text-[20px] font-[700] uppercase relative text-center left-[50%]",
        aboutCardTextOpended: "card-text-opened",
        aboutText: "flex items-center h-[350px] absolute w-[340px] z-[3]",
    aboutCardSvg: "absolute w-[340px] z-[1] max-h-[350px]",
}
