export const defaultStyle = {
    policySection: "mt-[-185px] pt-[185px] pb-[60px] bg-fonziYellow",
    policySectionHeader: "absolute top-0 max-h-[190px] w-full privacy-bg",
    policyHero: "max-h-[190px] w-full object-cover",
    policySectionWrapper: "max-w-[1180px] m-auto",
}

export const companyData = {
    policySection: "mt-[-185px] pt-[185px] pb-[60px] bg-fonziYellow",
    policySectionHeader: "absolute top-0 max-h-[190px] w-full privacy-bg",
    policyHero: "max-h-[190px] w-full object-cover",
    policySectionWrapper: "max-w-[1180px] m-auto flex flex-wrap",
    policySectionTitle:
        "font-HelveticaNeueBold mid:mt-[40px] slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[66px] py-[55px]",
}

export const cookiePolicyContent = {
    policySection: "mt-[-185px] pt-[185px] pb-[60px] bg-fonziYellow",
    policySectionHeader: "absolute top-0 max-h-[190px] w-full privacy-bg",
    policyHero: "max-h-[190px] w-full object-cover",
    policySectionWrapper: "max-w-[1180px] m-auto",
}