import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Image from "../../UI/Image/Image"
import * as styles from "./ModalWrapper-tw-styles"

const ModalWrapper = props => {
    const { closeModal, children, variant, image, closeOnBodyclick } = props
    const style = styles[variant] || styles.defaultStyle

    const handleCloseOnKeyPress = event => {
        if (event.key === "Escape" || event.key === "Esc") {
            closeModal()
        }
    }
    const onClick = () => {
        if (closeOnBodyclick) {
            closeModal()
        }
    }
    useEffect(() => {
        document.addEventListener("keydown", handleCloseOnKeyPress)

        return () => {
            document.removeEventListener("keydown", handleCloseOnKeyPress)
        }
    }, [])

    return (
        <div className={style?.modalwrpr} onClick={onClick}>
            <div className={style?.modalContainer}>
                {children}
                {image && <Image className={style?.closeBtn} contentfulImage={image} onClick={closeModal} />}
            </div>
        </div>
    )
}

ModalWrapper.propTypes = {
    closeModal: PropTypes.func,
    children: PropTypes.any,
    variant: PropTypes.string,
    closeOnBodyclick: PropTypes.bool,
    image: PropTypes.object,
}

export default ModalWrapper
