// /**
//  * Layout component that queries for data
//  * with Gatsby's useStaticQuery component
//  *
//  * See: https://www.gatsbyjs.com/docs/use-static-query/
//  */

import * as React from "react"
import "../../styles/fonts.css"
import PropTypes from "prop-types"
import Header from "../Globals/Header/Header"
import Footer from "../Globals/Footer/Footer"
import { get } from "../../helpers/utils"
import ScriptWrapper from "../Wrappers/ScriptWrapper/ScriptWrapper"

const Layout = ({ children, seoData, globalComponents }) => {
    const FooterProps = globalComponents?.find(component => component?.Footer)
    const HeaderProps = globalComponents?.find(component => component?.Header)
    const footerCollection = get(FooterProps, "Footer.footerCollection.items[0]", {})
    const headerCollection = get(HeaderProps, "Header.headerCollection.items[0]", {})

    return (
        <>
            <ScriptWrapper seoHead={seoData} />

            <div media="print" className="main-container text-darkBlue proximaNova-Regular,serif">
                <Header {...headerCollection} />
                <div className="content-container w-full">
                    <main>{children}</main>
                </div>
                <Footer {...footerCollection} />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    seoData: PropTypes.object,
    globalComponents: PropTypes.array,
}

export default Layout
