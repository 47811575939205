export const defaultStyle = {
    footerlinsMob: "footer py-[35px]  bg-fonziDarkRed text-white",
    FooterHead: "footerhead",
    footerWrapper: "flex flex-wrap text-center m-w-[1920px] m-auto lg:px-[15px]",
    footerWrap: "footerwrap w-full flex justify-center",
    fatFooter: "fatfooter",
    copyrightContainer:
        "copy w-full flex justify-center text-[11px] lg:text-[15px] my-[25px] font-HelveticaNeueRegular",
    footerSocialLinks: "soc-link-container w-full flex justify-center mb-[15px]",
    socialItem: "soc-link header-social-icon w-[45px] h-[45px] p-[0.5em] mx-[4px] rounded-[50%]",
    socialSvg: "soc-svg",
}

export const LOGO = {
    className: "p-2 white mx-auto",
}
