import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as styles from "./AboutCard-tw-styles"
import Image from "../Image/Image"
import Typography from "../Typography/Typography"

const AboutCard = props => {
    const { title, text } = props

    const [aboutCardOpened, setAboutCardOpened] = useState(false)

    const style = styles.defaultStyle

    const aboutCardRef = useRef(null)

    const handleMouseEnter = () => {
        setAboutCardOpened(true)
    }

    const handleMouseLeave = () => {
        setAboutCardOpened(false)
    }

    const handleOutsideClick = event => {
        if (aboutCardRef.current && !aboutCardRef.current.contains(event.target)) {
            setAboutCardOpened(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick)

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick)
        }
    }, [])

    const [isInSectionViewport, setIsInSectionViewport] = useState(false)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsInSectionViewport(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (aboutCardRef.current) {
            observer.observe(aboutCardRef.current)
        }

        // Cleanup function
        return () => {
            if (aboutCardRef.current) {
                observer.unobserve(aboutCardRef.current)
            }
        }
    }, [])

    return (
        <div
            className={`${style?.aboutCard} ${isInSectionViewport ? "text-fade-in-animation" : ""} ${
                aboutCardOpened ? "about-card-hover" : ""
            }`}
            ref={aboutCardRef}
        >
            <Typography
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                content={title}
                className={style?.aboutCardTitle}
            />
            <div className={`${style?.aboutCardText} ${aboutCardOpened ? style?.aboutCardTextOpended : ""}`}>
                <svg
                    className={style?.aboutCardSvg}
                    id="fonzies-shape"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 214 292"
                >
                    <path
                        fill="#b7261e"
                        d="M116.2 282.2c3.7 2 7.4 4.3 11.3 6.1 1.4.6 8.9 5.6 8.7 2.8-11.4-19-26.9-41.1-20.3-64.4 12.1-37.2 79.3-43.1 94.4-86.4 16.5-46.8-23.1-88.5-59.1-111.9-8.5-5.6-16.6-10.5-25.4-15.1-3.6-1.5-36-19.3-30.1-10.6 7.3 6.8 14.6 17.6 13 27.9-2.8 22-42.7 31.6-60 41.6C35.1 79.1 22.5 89 14 101.8c-10.1 15-15.5 34.7-14 53.1 4 41.8 36.3 74.4 69.2 97.2 15.3 10.6 30.8 20.9 47 30.1"
                    />
                </svg>
                <Typography content={text} className={style?.aboutText} />
            </div>
        </div>
    )
}

export default AboutCard

AboutCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
}
