import React from "react"
import NavigationItem from "./NavigationItem/NavigationItem"
import PropTypes from "prop-types"
import * as styles from "./Navigation-tw-styles"

const Navigation = ({ navigation, variant }) => {
    const style = styles[variant] || styles.defaultStyle
    const { navContainer } = style

    return (
        <ul className={navContainer}>
            {navigation?.length > 0 && navigation.map((item, index) => <NavigationItem item={item} key={index} />)}
        </ul>
    )
}

Navigation.propTypes = {
    navigation: PropTypes.array,
    variant: PropTypes.string,
}

export default Navigation
