export const defaultStyle = {
    navContainer: "nav-container-mobile z-[666]",
    mobileNavActive: "fixed w-full h-[100vh] left-0 right-0 bottom-[100vh] bg-fonziRed",
    navContent: "nav-content-mobile flex flex-wrap flex-col justify-center items-center h-[100vh] text-center",
    navItems: "nav-items w-full pt-[40px]",
    navToggle: "flex items-center fixed right-[30px] top-[30px] z-[667]",
    navToggleIcon: "cursor-pointer w-[52px] h-[52px]",
    navToggleIconRelocate: "absolute right-[30px] top-[38px]",
    navgationItem: "nav-item ",
    socialIconContainer: "flex items-center sm:my-[20px] my-[50px]",
    socialIcon: "header-social-icon w-[38px] h-[38px] p-[0.5em] mx-[4px] rounded-[50%]",
    socialSvg: "mobile-social-image",
    menuSvgContainer: "menu-svg-container border border-fonziRed bg-fonziYellow rounded-[90px] z-[667]",
    menuCloseSvgContainer: "bg-fonziYellow border rounded-[90px] absolute right-[30px] top-[38px]",
    mobileMenuSvg: "w-[52px] h-[52px] fill-fonziRed",
    mobileMenuCloseSvg: "w-[52px] h-[52px] fill-fonziRed",
    mobileMenuBgSvg:
        "mid:z-[0] z-[1] w-[425px] h-[360px] absolute flex m-auto justify-center items-center top-[140px] mid:top-[unset] left-1/2 transform -translate-x-1/2",
}
