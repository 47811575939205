import React, { useEffect } from "react"
import NavigationItem from "./NavigationItem/NavigationItem"
import Image from "../../../../UI/Image/Image"
import Link from "../../../../UI/Link/Link"
import PropTypes from "prop-types"
import * as styles from "./Navigation-tw-styles"

const Navigation = ({ navigation, isMobileNavOpen, toggleMobileNav, socialLinksData, variant, navCloseAnimation, setNavCloseAnimation }) => {
    const style = styles[variant] || styles.defaultStyle
    const {
        navContainer,
        mobileNavActive,
        navItems,
        navContent,
        navToggle,
        navgationItem,
        socialIconContainer,
        socialIcon,
        socialSvg,
        menuSvgContainer,
        menuCloseSvgContainer,
        mobileMenuSvg,
        mobileMenuCloseSvg,
        mobileMenuBgSvg
    } = style

    useEffect(() => {
        if(isMobileNavOpen) {
            setNavCloseAnimation(false)
        } else {
            setNavCloseAnimation(true)
        }
    }, [isMobileNavOpen])

    const renderNavigationItems = () => (
        <div className={navContent}>

            <ul className={navItems}>
            <svg
                    className={mobileMenuBgSvg}
                    id="fonzies-shape"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 214 292"
                >
                    <path
                        fill="#b7261e"
                        d="M116.2 282.2c3.7 2 7.4 4.3 11.3 6.1 1.4.6 8.9 5.6 8.7 2.8-11.4-19-26.9-41.1-20.3-64.4 12.1-37.2 79.3-43.1 94.4-86.4 16.5-46.8-23.1-88.5-59.1-111.9-8.5-5.6-16.6-10.5-25.4-15.1-3.6-1.5-36-19.3-30.1-10.6 7.3 6.8 14.6 17.6 13 27.9-2.8 22-42.7 31.6-60 41.6C35.1 79.1 22.5 89 14 101.8c-10.1 15-15.5 34.7-14 53.1 4 41.8 36.3 74.4 69.2 97.2 15.3 10.6 30.8 20.9 47 30.1"
                    />
                </svg>
                {navigation?.length > 0 &&
                    navigation.map((item, index) => (
                        <NavigationItem item={item} key={index} className={navgationItem} />
                    ))}
            </ul>
            <div className={socialIconContainer}>
                {socialLinksData.map((item, index) => {
                    return (
                        <div className={socialIcon} key={index}>
                            <Link url={item?.url}>
                                <Image className={socialSvg} contentfulImage={item?.image} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
    return (
        <div className={`${navContainer} ${isMobileNavOpen ? mobileNavActive : ""} ${!navCloseAnimation ? "mobile-nav-animation" : ""}`}>
            <div className={navToggle} onClick={toggleMobileNav}>
                <span className={`uppercase mr-[5px] text-[21px] text-darkBlue`}>
                    {" "}
                    {isMobileNavOpen ? "" : ""}
                </span>
                <div className={menuSvgContainer} >
                {isMobileNavOpen ? <svg className={mobileMenuCloseSvg} id="icon-close-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path d="M30.2 28.8c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3s-.5-.1-.7-.3L26 27.5l-2.7 2.8c-.2.1-.5.2-.8.2s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4l2.7-2.8-2.9-2.9c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l2.9 2.9 2.9-2.9c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L27.3 26l2.9 2.8z"/></svg>
 : <svg className={mobileMenuSvg} id="icon-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path d="M26 15.5h11.1c.4 0 .8.2 1.1.5.3.4.4 1 .2 1.5-.1.5-.5.8-1 .9H14.8c-.3 0-.7-.2-.9-.4-.4-.4-.5-1-.3-1.6.1-.5.5-.9 1-1H26zm0 9h11c.5-.1 1 .1 1.2.6.3.4.3 1 .2 1.5-.1.5-.5.8-.9 1H14.9c-.8 0-1.4-.6-1.4-1.4v-.3c0-.7.5-1.3 1.2-1.3H26zm-2 12h-9.2c-.7 0-1.3-.6-1.3-1.3V35c0-.9.5-1.5 1.2-1.5h18.5c.9 0 1.4.6 1.4 1.5 0 .8-.5 1.4-1.2 1.4H24zM35.5 35c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5"/></svg>
}
                </div>

            </div>
            {isMobileNavOpen && renderNavigationItems()}
        </div>
    )
}

Navigation.propTypes = {
    navigation: PropTypes.array,
    isMobileNavOpen: PropTypes.bool,
    navCloseAnimation: PropTypes.string,
    setNavCloseAnimation: PropTypes.func,
    toggleMobileNav: PropTypes.func,
    socialLinksData: PropTypes.array,
    variant: PropTypes.string,
}

export default Navigation
