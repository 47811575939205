export const defaultStyle = {
    firstSection: "mt-[-185px] pt-[230px]",
    bannerSection: "h-[810px] pb-[180px] sm:pb-0 overflow-hidden md:h-full flex",
    bannerSectionBgImage: "h-[305px] md:h-[260px] absolute w-full",
    bannerBgImage: "h-[305px] md:h-[260px] w-full lg:object-none",
    bannerSectionLeftImage: "w-[33%] flex relative",
    leftImage: "",
    bannerSectionMain: "relative flex sm:flex-wrap sm:px-[15px] w-[33%] md:w-[45%] sm:w-full justify-center items-center",
    bannerSectionText: "banner-text-box mt-[40px] sm:w-full z-[3]",
    bannerSectionWatermark: "absolute sm:top-[-40px] z-[1] left-1/2 transform -translate-x-1/2",
    bannerMobileImageContainer: "sm:z-[3]",
    bannerMobileImage: "",
    watermarkSvg: "w-[360px] sm:w-[285px]",
    bannerSectionRightImage: "w-[33%] flex relative",
    rightImage: "",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
    leftImageWrapper: "flex absolute right-0",
    rightImageWrapper: "flex absolute left-0",
    multiplyImage: "w-[320px]",
}

export const homepage = {
    firstSection: "mt-[-185px] pt-[80px]",
    bannerSection: "overflow-hidden  flex",
    bannerSectionBgImage: "w-full l:pt-[55px] sm:pt-0",
    bannerBgImage: "  w-full lg:object-contain",
    bannerSectionLeftImage: "w-[33%] flex relative",
    leftImage: "",
    bannerSectionMain: "relative flex w-[33%] justify-center items-center",
    bannerSectionText: "mt-[40px]",
    bannerSectionWatermark: "",
    bannerSectionRightImage: "w-[33%] flex relative",
    rightImage: "",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
    leftImageWrapper: "flex absolute right-0",
    rightImageWrapper: "flex absolute left-0",
    multiplyImage: "w-[320px]",
}
