export const defaultStyle = {
    slideshowContainer: "relative h-[450px]",
    slideshowContainerMediaImageContainer: "",
    slideshowContainerMediaImage: "h-[450px] object-cover w-full",
    slideWrapper: "max-w-[1280px] m-auto",
    slideshowTextWrapper: "absolute left-0 bottom-[60px] w-full ",
    slideshowItemBox: "max-w-[1280px] m-auto relative",
    slideshowContainerMediaTextContainer: " md:px-[10px] relative md:z-10",
    slideshowContainerMediaFootnote: "w-full text-white font-ZonaProRegular text-center text-[12px] mt-[15px]",
    slideshowContainerMediaSideImage: "absolute right-0 bottom-[0px]",
    sideimage: "max-w-[500px] md:max-w-[400px] max-h-[400px] md:z-[1]",
    slideshowControllers: "relative w-fit m-auto bottom-[50px]",
    swiperPagination: "swiper-pagination !relative m-auto w-full flex flex-wrap justify-center items-center",
    slideshowPaginationActiveItemStyle:
        "text-transparent font-extrasmall leading-0 flex w-[15px] h-[15px] p-0 cursor-pointer border-0 outline-none rounded-[10px] bg-white m-[10px]",
    slideshowPaginationItemStyle:
        "text-transparent font-extrasmall leading-0 flex w-[15px] h-[15px] p-0 cursor-pointer border-0 outline-none rounded-[10px] bg-white bg-opacity-40 m-[10px]",
    buttonNext: "after:hidden !right-[-40px]",
    buttonPrev: "after:hidden !left-[-40px]",
    textPosition: {
        left: "flex justify-start",
        center: "flex justify-center",
        right: "flex justify-end",
    },
}

export const timeline = {
    firstSection: "mt-[-185px] pt-[185px]",
    sectionWrapper: "",
    slideshowYellowBg: "",
    slideshowContainer: "slideshow-container overflow-hidden",
    swiper: "sm:mb-[-70px]",
    slideshowContainerMediaImageContainer: "",
    slideshowContainerMediaImage: "",
    swiperSlide: "relative",
    slideWrapper: "slide-wrapper flex justify-around",
    slideshowTextWrapper: "",
    slideshowItemBox: "",
    slideshowContainerMediaTextContainer: " ",
    slideshowContainerMediaFootnote: "",
    slideshowContainerMediaSideImage: "",
    sideimage: "",
    buttonNext:
        "after:hidden !right-[-100px] lg:!right-[-75px] sm:!right-[-50px] !h-[200px] lg:!h-[150px] sm:!h-[100px] !w-[200px] lg:!w-[150px] sm:!w-[100px] bg-fonziRed !top-[-435px] sm:!top-[-920px] rounded-[200px]",
    buttonPrev:
        "after:hidden !left-[-100px] lg:!left-[-75px] sm:!left-[-50px] !h-[200px] lg:!h-[150px] sm:!h-[100px] !w-[200px] lg:!w-[150px] sm:!w-[100px] bg-fonziRed !top-[-435px] sm:!top-[-920px] rounded-[200px]",
    navigationWrapper: "relative flex",
    navigationPrevYear:
        "absolute left-[45px] rotate-[90deg] text-[60px] lg:text-[50px] sm:text-[35px] text-fonziYellow ml-[30px] lg:ml-[15px] sm:ml-[5px] sm:mt-[10px]",
    navigationNextYear:
        "absolute right-[-70px] rotate-[-90deg] text-[60px] lg:text-[50px] sm:text-[35px] text-fonziYellow mr-[30px] lg:mr-[35px] sm:mr-[48px] sm:mt-[10px]",
    navigationNextSvg: "w-[80px] ml-[-110px] lg:ml-[-95px] sm:ml-[-70px] rotate-[180deg]",
    navigationPrevSvg: "w-[80px] ml-[145px] lg:ml-[110px] sm:ml-[70px]",
    slideshowControllers: "w-[100%] relative",
    swiperPagination: "swiper-pagination max-h-[680px] overflow-y-scroll overflow-hidden scrollbar-hide",
    paginationWrapper: "",
    timelineWrapper:
        "timeline h-[820px] l:h-[600px] sm:h-full flex sm:flex-wrap max-w-[1230px] justify-start items-center",
    timelineFirstCol:
        "timeline-first-col max-w-[850px] l:max-w-[500px] lg:max-w-[490px] px-[160px] l:px-[0] l:pl-[100px] lg:pl-[90px] sm:px-[20px] z-[4]",
    timelineYear:
        "text-center text-[76px] lg:text-[50px] font-HelveticaNeueBold text-fonziRed sm:pt-[40px] sm:pb-[20px]",
    timelineText: "",
    timelineLinkContainer: "flex items-center justify-center",
    timelineLinkText:
        "mr-[50px] pl-[20px] relative text-fonziRed after:absolute after:left-0 after:top-0 after:w-[6px] after:h-full after:content-[''] after:block after:border-[3px] after:rounded-[3px] after:bg-fonziRed",
    timelineVideoContainer: "video-cont cursor-pointer w-[135px] h-[135px] relative flex justify-center items-center",
    timelineCoverImage: "w-[135px] h-[135px] p-[10px] z-[1] overflow-hidden rounded-[90px] absolute left-0",
    playSvg: "w-[135px] z-[2]",
    timelineSecondCol:
        "timeline-second-col absolute laptop:flex laptop:justify-center laptop:items-center sm:relative h-full right-0 l:right-[-70px] sm:left-0 lg:flex lg:items-center z-[3]",
    timelineImage: "h-[820px] laptop:h-[550px] l:h-[340px] sm:h-[450px] object-contain",
    diffTimelineImage: "h-[820px] l:h-[600px] mid:relative mid:right-[-110px] md:right-[unset] sm:h-[450px]",
    pattern: {
        triangles: "slideshow-triangles",
        dots: "slideshow-dots",
        waves: "slideshow-waves",
        bubbles: "slideshow-bubbles",
    },
    videoPopup: "fixed bg-[rgba(0,0,0,.5)] z-[999] top-0 left-0 right-0 bottom-0 w-full h-[100vh]",
    videoPopupWrapper: "w-full relative justify-center h-full items-center",
    videoPopupClose:
        "cursor-pointer video-popup-close-animation w-[54px] h-[54px] rounded-[90px] bg-fonziYellow absolute right-[10px] laptop:right-[30px] top-[60px]",
    videoPopupCloseSvg: "fill-fonziRed",
    videoYoutube: "timeline-video-div h-full m-auto w-[1200px] max-h-[650px] relative flex justify-center items-center",
}

export const productList = {
    firstSection: "mt-[-185px] pt-[185px] laptop:pt-[130px] mid:pt-[155px] md:pt-[130px]",
    sectionWrapper: "bg-fonziRed overflow-hidden relative h-[100vh] laptop:h-[115vh] md:h-[100%]",
    slideshowYellowBg: "absolute w-[60%] l:w-[50%] sm:w-full h-full bg-fonziYellow left-0 top-0",
    slideshowContainer: "slideshow-container m-auto p-[30px] flex sm:z-[6]",
    swiper: "w-[100%]  laptop:w-[75%] lg:w-[50%] sm:w-full lg:!ml-0 sm:!m-auto z-[6]",
    slideshowContainerMediaImageContainer: "",
    slideshowContainerMediaImage: "",
    swiperSlide: "relative max-h-[650px] lg:max-h-[820px] sm:max-h-[620px] flex justify-center items-center",
    slideWrapper:
        "slide-wrapper flex lg:flex-wrap lg:mt-[45px] justify-around ml-[150px] laptop:mt-[-100px] laptop:ml-[80px] l:ml-0",
    slideshowTextWrapper: "",
    slideshowItemBox: "",
    slideshowContainerMediaTextContainer: " ",
    slideshowContainerMediaFootnote: "",
    slideshowContainerMediaSideImage: "",
    sideimage: "",
    slideshowControllers: "w-[25%] l:w-[30%] lg:w-[43%] sm:hidden sm:w-full relative",
    slideshowControllersMobile:
        "mobile-controller w-full h-0 z-[-1] fixed left-0 bottom-0 right-0 z-[6] custom-product-list-bg p-[20px] bg-fonziRed",
    slideshowControllersMobileOpened: "mobile-controller-opened visible !z-[6] !block h-[100%]",
    swiperPagination:
        "swiper-pagination max-h-[80vh] laptop:max-h-[90vh] md:max-h-[100vh] overflow-y-scroll overflow-hidden scrollbar-hide",
    paginationWrapper: "sidebar-controller-container h-full",
    slideshowPaginationActiveItemStyle:
        "relative z-[4] w-[30px] h-[30px] flex justify-center items-center border-[1px] border-fonziRed rounded-[30px] mt-[10px] text-white bg-fonziRed",
    slideshowPaginationItemStyle:
        "w-[30px] h-[30px] flex justify-center items-center border-[1px] border-fonziRed rounded-[30px] mt-[10px] text-fonziRed",
    slideshowPaginationLeftSvg: "relative z-[5] w-[30px] h-[30px]",
    buttonNext: "after:hidden !right-[-40px]",
    buttonPrev: "after:hidden !left-[-40px]",
    textPosition: {
        left: "flex justify-start",
        center: "flex justify-center",
        right: "flex justify-end",
    },
    paginationImage: "w-[134px] h-[134px]",
    slideLeftCol:
        "slide-left-col w-[45%] lg:w-full relative flex flex-col flex-wrap justify-start items-center mt-[-30px] laptop:mt-0 lg:px-[10px]",
    slideTitle:
        "font-HelveticaNeueBold z-[3] leading-[1] text-[82px] laptop:text-[60px] l:text-[50px] text-white text-center uppercase product-title-shadow",
    slidePackSize: "font-HelveticaNeueRegular z-[3] text-[20px] text-fonziRed uppercase text-center font-[700]",
    slideControls: "flex z-[3] justify-between gap-[10px] l:flex-wrap",
    ingredientButton:
        "text-[14px] cursor-pointer w-fit l:w-full lg:m-auto lg:w-[180px] lg:flex lg:justify-center font-[700] font-HelveticaNeueBold leading-[1] uppercase border border-fonziRed text-fonziYellow bg-fonziRed py-[10px] px-[60px] rounded-[19px] inline-block text-center no-underline",
    linkToContent:
        "text-[14px] w-fit l:w-full lg:w-[180px] lg:flex lg:justify-center m-auto font-[700] font-HelveticaNeueBold leading-[1] uppercase border border-fonziRed text-fonziRed py-[10px] px-[60px] rounded-[19px] inline-block text-center no-underline",
    slideRightCol: "slide-right-col w-[50%] laptop:w-[35%] lg:w-full mt-[-100px] laptop:mt-0",
    slideImage: "lg:m-auto lg:w-[240px] lg:mt-[40px] sm:z-[6] sm:mt-[20px]",
    ingredientsSidebar:
        "ingredients-sidebar w-[25%] laptop:w-[26%] l:relative l:top-[-25px] sm:w-full sm:fixed sm:top-[90px] sm:left-0 sm:right-0 sm:bottom-0 sm:bg-fonziRed sm:flex sm:justify-center max-h-[680px] z-[6] sm:h-[70%] sm:overflow-y-scroll",
    ingredientsWrapper: "w-[325px] flex relative sm:w-[360px]",
    closeIngredientsSidebar:
        "absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:z-[666] sm:top-[160px] cursor-pointer right-[-20px] bg-fonziRed top-[20px] w-[40px] h-[40px] border rounded-[30px] flex justify-center items-center text-white border-black",
    closePagination: "",
    productListPagination:
        "product-pagination-card-shadow laptop:max-w-[300px] sm:max-w-[310px] xlg:max-w-[350px] h-[145px] mb-[20px] bg-fonziYellow rounded-[8px] relative cursor-pointer overflow-hidden flex p-[20px] justif-between",
    paginationLeftCol: "w-[43%]",
    paginationTitle: "font-HelveticaNeueRegular leading-[1.1] uppercase text-[18px] text-left text-fonziRed",
    paginationPackageSize: "font-HelveticaNeueRegular text-[10px] pt-[5px] text-left text-fonziRed",
    paginationRightCol: "w-[50%] relative z-[3]",
    paginationImage: "w-[123px] h-[123px] object-contain mt-[-10px]",
    productSideRuler:
        "product-list-ruler absolute flex justify-between left-[-60px] laptop:left-[95px] lg:left-[-170px] sm:left-[155px] top-[56.5%] laptop:top-[44.5%] lg:top-[56%] mid:top-[57%] sm:top-[31%] w-[770px] laptop:w-[425px] lg:w-[940px] sm:w-[445px] rotate-[90deg] sm:z-[66]",
    productSideRulerTitle: "font-HelveticaNeueRegular text-fonziYellow leading-[1.1] uppercase text-[18px]",
    productSideRulerScroll:
        "sidebar-extra text-fonziYellow font-HelveticaNeueRegular leading-[1.1] uppercase text-[13px]",
    slideBgSvgContainer: "absolute z-[1] top-[-40px] sm:top-[-45px] w-[180px] h-[250px]",
    slideBgSvg: "w-[180px] h-[250px]",
    paginationBgSvgContainer: "absolute z-[1] w-[145px] right-[-20px] top-[-35px]",
    paginationBgSvg: "",
    mobileControllerCover: "h-[105px]",
    mobileSwiperController:
        "mobile-product-controller-box flex relative m-auto z-[6] h-[105px] w-full bg-fonziYellow justify-between",
    mobileSwiperControllerIngredients: "w-[33%] m-auto flex flex-wrap justify-center items-center",
    mobileIngredientSvg: "w-[40px] h-[40px]",
    mobileIngredientP: "w-full text-center font-HelveticaNeueRegular uppercase text-fonziRed",
    mobileSwiperControllerShop: "w-[33%] relative flex flex-wrap justify-center items-center",
    mobileSwiperControllerBorder: " bg-fonziYellow p-[20px] mt-[-100px] rounded-[90px] border border-fonziRed",
    mobileSwiperShopSvg: "w-[25px] h-[25px]",
    mobileSwiperControllerProducts: "w-[33%] m-auto flex flex-wrap justify-center items-center",
    mobileSwiperControllerProductsActive: "",
    mobileSwiperProductsSvg: "w-[40px] h-[40px]",
    mobileSwiperProductsP: "w-full text-center font-HelveticaNeueRegular uppercase text-fonziRed",
    productCloseButtonContainer:
        "absolute cursor-pointer left-1/2 transform -translate-x-1/2 rounded-[90px] top-[155px] border border-black bg-fonziRed z-[666]",
    productCloseButton: "w-[40px] h-[40px]",
}

export const youtube = {
    sectionWrapper:
        "m-auto max-w-[1780px] laptop:max-w-[1320px] l:max-w-[1060px] lg:max-w-[700px] sm:max-w-[425px] pt-[50px] pb-[180px]",
    slideshowYellowBg: "",
    slideshowContainer: "slideshow-container overflow-hidden",
    swiper: "",
    slideshowContainerMediaImageContainer: "",
    slideshowContainerMediaImage: "",
    swiperSlide: "relative !w-[550px] laptop:!w-[400px] l:!w-[320px] !h-[310px] laptop:!h-[225px] l:!h-[180px]",
    videoIframe: "!w-[550px] laptop:!w-[400px] l:!w-[320px] !h-[310px] laptop:!h-[225px] l:!h-[180px]",
    slideWrapper: "slide-wrapper flex justify-around",
    playButtonContainer: "absolute top-0 w-[60px]",
    swiperPagination: "youtube-pagination-container flex md:hidden mt-[40px] justify-center",
    slideshowPaginationItemStyle: "item w-[11px] h-[11px] m-[5px] rounded-full bg-opacity-40",
    slideshowPaginationActiveItemStyle: "item-active w-[11px] h-[11px] m-[5px] rounded-full",
    slideshowPaginationColor: {
        white: "bg-white",
        yellow: "bg-yellow",
        red: "bg-red",
    },
}

export const interactive = {
    firstSection: "mt-[-185px] pt-[185px]",
    sectionWrapper: "relative",
    slideshowContainer:
        "slideshow-container lg:h-[700px] sm:h-[730px] max-w-[1920px] laptop:max-w-[1440px] l:max-w-[1180px] lg:max-w-[820px] sm:max-w-[425px] relative p-[30px] sm:p-0 sm:px-[10px] flex",
    swiper: "w-[100%] z-[6] ",
    swiperSlide: "relative flex justify-center items-center",
    slideWrapper: "slide-wrapper flex sm:flex-wrap justify-center lg:mb-auto",
    slideshowTextWrapper: "",
    slideshowItemBox: "",
    sideimage: "",
    slideshowControllers:
        "w-[800px] laptop:max-w-[600px] l:max-w-[470px] sm:max-w-[320px] absolute right-[0] sm:right-[-45px] bottom-[100px] lg:bottom-0",
    swiperPagination:
        "swiper-pagination mid:ml-[-35px] md:ml-0 mid:!relative right-[-50px] flex max-w-[800px] laptop:max-w-[600px] l:max-w-[470px] sm:max-w-[250px] sm:ml-[-50px]",
    paginationWrapper: "h-[220px] laptop:h-[200px] sm:h-[110px] flex items-center bg-fonziRed z-[6] relative",
    interactivePagination: "",
    navigationNextSvg: "w-[90px] sm:w-[70px] ml-[40px] sm:ml-[20px]",
    navigationWrapper: "interactive-nav-wrapper",
    paginationSwiperSlide: "flex flex-col justify-end w-[155px] sm:w-[90px] h-[140px] sm:h-[88px]",
    navigationImageContainer: "w-[155px] sm:w-[90px] h-[140px] sm:h-[88px] sm:flex sm:justify-center sm:m-auto",
    paginationImage: "flex justify-center h-[140px] sm:h-[88px]",
    slideshowPaginationActiveItemStyle: "w-[30px] h-[30px] ",
    slideshowPaginationItemStyle: "w-[30px] h-[30px] ",
    buttonNext: "after:hidden !left-[-125px] !w-[125px]",
    buttonPrev: "after:hidden !left-[-125px] !w-[125px]",
    slideLeft: "w-[49%] sm:w-full sm:order-2 p-[40px] relative my-[40px] sm:p-0 sm:w-[75%]",
    slideImageContainer: "",
    slideImage: "",
    slideDetailsContainer: "absolute w-full h-full top-0 left-0",
    slideDetail: "slide-detail ",
    slideDetailOuterCircle: "detail-outer-circle relative",
    slideDetailInnerCircle: "detail-inner-circle",
    slideDetailInfoBox: "detail-info-box",
    slideLink:
        "btn-hover-animation mid:absolute mid:right-0 mid:ml-0 relative font-HelveticaNeueBold ml-[40%] flex justify-center items-center w-fit border border-[1] border-fonziRed rounded-[22px] px-[35px] py-[12px] uppercase font-[600] text-fonziRed text-[14px]",
    slideRight: "w-[49%] sm:w-full sm:order-1",
}
