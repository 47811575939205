const query = (id, isPreview) => `
{
    twoColumnSection (id: "${id}", preview: ${isPreview}) {
        firstColumnCollection(limit: 5) {
            items {
                __typename
                ... on MediaElement {
                  media {
                    url
                  }
                }
                ... on IconElement {
                    image {
                        url
                    }
                    link {
                        label
                        title
                        url
                        target
                        scrollToElementId
                    }
                }
                ... on RichTextModel {
                    variant
                    title
                    richText{
                        json
                        links {
                            entries {
                                inline {
                                    sys{
                                        id
                                        }
                                    ... on Link {
                                        label
                                        url
                                        target
                                    }
                                }
                            }
                        }    
                    }
                    link {
                        label
                        title
                        url
                        target
                        scrollToElementId
                    }
                }
            }
        }
        secondColumnCollection(limit: 5) {
            items {
                __typename
                ... on MediaElement {
                    media {
                    url
                  }
                }
                ... on IconElement {
                    image {
                        url
                    }
                    link {
                        label
                        title
                        url
                        target
                        scrollToElementId
                    }
                }
                ... on RichTextModel {
                    variant
                    title
                    richText{
                        json
                        links {
                            entries {
                                inline {
                                    sys{
                                        id
                                        }
                                    ... on Link {
                                        label
                                        url
                                        target
                                    }
                                }
                            }
                        }    
                    }
                    link {
                        label
                        title
                        url
                        target
                        scrollToElementId
                    }
                }
            }
        }
        variation
        scrollToContent
        firstSection
        backgroundColor
    }
}
`

module.exports = { query }
