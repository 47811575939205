const query = isPreview => `{
  footerCollection(preview: ${isPreview}, limit: 1) {
    items {
      note
      socialLinksCollection(limit: 10) {
        items {
          label
          title
          url
          target
          imageType
          scrollToElementId
          gaEventClass
          gaEventLabel
          image {
            title
            description
            url
            width
            height
          }
        }
      }
      mainLinksCollection(limit: 15) {
        items {
          label
          linksCollection {
            items {
              label
              title
              url
              target
              scrollToElementId
              gaEventClass
              gaEventLabel
              image {
                title
                description
                url
                width
                height
              }
            }
          }
        }
      }
      brandLinksCollection {
        items {
          url
          gaEventLabel
          gaEventClass
          image {
            url
            title
            description
            width
            height
          }
        }
      }
    }
  }
}

  `
module.exports = { query }
