import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as styles from "./AboutSection-tw-styles"
import { toCamelCase } from "../../helpers/utils"
import RichTextModel from "../RichTextModel/RichTextModel"
import AboutCard from "../UI/AboutCard/AboutCard"

const AboutSection = props => {
    const { text, cardsCollection, backgroundColor } = props

    const style = styles.defaultStyle

    const bgColor = toCamelCase(backgroundColor)

    const sectionRef = useRef(null);

    const [isInSectionViewport, setIsInSectionViewport] = useState(false);

    useEffect(() => {
        const options = {
            root: null, 
            rootMargin: "0px", 
            threshold: 0.5, 
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsInSectionViewport(true);
                    observer.disconnect(); 
                }
            });
        }, options);

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        // Cleanup function
        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className={`${style?.aboutSection} ${style?.backgroundColor[bgColor]}`}>
            <div className={`${style?.aboutSectionTopText} ${isInSectionViewport ? "text-fade-in-animation" : ""}`} ref={sectionRef}>
                <RichTextModel {...text} />
            </div>
            <div className={style?.aboutSectionCardContainer}>
                {cardsCollection?.items.map((item, index) => {
                    return <AboutCard {...item} key={index} />
                })}
            </div>
        </div>
    )
}

export default AboutSection

AboutSection.propTypes = {
    text: PropTypes.object,
    cardsCollection: PropTypes.object,
    backgroundColor: PropTypes.string,
}
