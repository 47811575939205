export const defaultStyle = {
    ingredientsText:
        "flex flex-wrap text-[15px] ingredientsText mt-40px mb-20px bg-white  lg:w-[1170px] mx-auto py-20 opacity-[.4] text-left lg:max-w-[94%] px-[6%]",
    richTextWrapper:
        "text-left text-[15px] text-darkBlue opacity-[.4] mb-20px lg:w-[972px] my-[45px] lg:mx-auto mx-15 leading-5",
    title: "text-center text-25 leading-25 py-25 tracking-[1px]",
}

export const FOOT_NOTE = {
    richTextWrapper:
        "text-left mb-20px lg:w-[972px] lg:my-[45px] lg:mb-[85px] m-10 lg:mx-auto my-0 opacity-50 text-15 tracking-[0] leading-[20px] md:mx-40 ",
}

export const INGREDIENTS_TEXT = {
    richTextWrapper: "text-left ingredientsText mt-40px mb-20px lg:w-[1170px] lg:my-[45px] mx-auto my-0 hidden",
}
export const TEXT_CONTAINER_SECTION = {
    richTextWrapper:
        "text-left rangeINgredTxt sm:px-8 pt-[50px] lg:pt-[40px] pb-[25px] lg:w-[1170px] text-[15px] lg:my-[45px] mx-auto my-0 text-center ",
    title: "",
}
export const COMPANY_DETAILS = {
    richTextWrapper:
        "richTextWrapper text-left sm:px-8 pt-[50px] lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
    title: "text-center text-25 leading-25 py-25 tracking-[1px]",
    code: "",
    paragraph: "paragraph",
    list: "",
    ulList: "",
    mainLink: "",
    link: "text-errorRed",
}
export const CORPORATE_DETAILS = {
    richTextWrapper:
        "richTextWrapper COMPANY_DETAILS text-left md:px-0 px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] mx-15 lg:mx-auto my-0",
    title: "text-center text-30 leading-30 pb-20 pt-5 tracking-[1px]",
    code: "code",
    paragraph: "text-errorRed",
    list: "",
    ulList: "",
    mainLink: "mainLink",
    link: "text-errorRed",
}

export const COOKIES_INFO = {
    richTextWrapper:
        "richTextWrapper text-left lg:px-8 px-8 lg:pt-0 pb-[25px] lg:w-[1170px] text-[15px] lg:mb-[45px] lg:mx-auto my-0 md:px-30 COOKIES_INFO",
    title: "text-center text-25 leading-25 py-25 tracking-[1px] ",
    code: "",
    paragraph: "paragraph",
    list: "list pb-[1.1em] list-none tracking-[0]",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "border-separate border-spacing-0 curved-table lg:mb-30 mb-10",
    tRow: "rounded-[0px]",
    tHeader: "bg-greyLightSmoke  text-14 first:rounded-tl-[5px] last:rounded-tr-[5px]",
    tData: "",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const TEXT_BOX_WITH_BUTTON = {
    richTextWrapper:
        "sm:px-8 PhillyFiveHdng richTextWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[2%] my-0 text-center",
    btnWrpr: "mt-20 buttonEffect2 ",
    title: "title",
    Heading: "text-[40px]",
    mainLink: "buttonEffect2 lg:mt-20 ",
    contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
}

export const TEXT_BOX_WITH_BUTTON_SPACE = {
    richTextWrapper:
        "sm:px-8 PhillyFiveHdng richTextWrapper pb-[25px] lg:w-[720px] text-[21px] lg:text-[25px] lg:mb-[45px] lg:mx-auto mx-[10%] my-0 text-center mt-60",
    btnWrpr: "mt-20 buttonEffect2 ",
    title: "title",
    Heading: "text-[40px]",
    mainLink: "buttonEffect2 lg:mt-20 ",
    contentDesc: "lg:text-[25px] text-[21px] leading-8 pt-3 mb-30 text-[#7f7f7f]",
}

export const PRODUCT_TEXT = {
    richTextWrapper: "rich-wrapper z-[3] relative my-[30px]",
    title: "text-center",
    code: "",
    paragraph:
        "paragraph text-center text-[14px] font-HelveticaNeueRegular text-fonziRed sm:text-white line-height-[1.6] tracking-[1px] font-[500]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "border-separate border-spacing-0 curved-table lg:mb-30 mb-10",
    tRow: "rounded-[0px]",
    tHeader: "bg-greyLightSmoke text-14 first:rounded-tl-[5px] last:rounded-tr-[5px]",
    tData: "",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const PRODUCT_DETAILS_TEXT = {
    richTextWrapper: "rich-wrapper py-[40px] bg-fonziRed",
    title: "font-HelveticaNeueRegular py-[10px] px-[20px] text-fonziRed line-height-[1.6] tracking-[1px] font-[500] w-full text-center bg-fonziYellow",
    code: "",
    heading4:
        "font-HelveticaNeueRegular py-[10px] px-[20px] text-fonziRed line-height-[1.6] tracking-[1px] font-[500] w-full text-center bg-fonziYellow",
    paragraph:
        "paragraph product-details-paragraph text-white font-HelveticaNeueRegular font-[600] text-[12px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const BANNER_TEXT = {
    richTextWrapper: "rich-wrapper",
    title: "font-HelveticaNeueBold custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[82px] l:text-[59px] sm:mb-[15px] sm:text-[56px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular py-[10px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[500] w-full text-center bg-fonziYellow",
    paragraph:
        "paragraph product-details-paragraph text-white font-HelveticaNeueRegular font-[600] text-[12px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const CONTEST_CARD_TEXT = {
    richTextWrapper: "rich-wrapper p-[40px] max-w-[340px] text-center m-auto",
    title: "font-HelveticaNeueRegular custom-line-height font-[700] mb-[10px] text-[42px] text-fonziRed",
    code: "",
    heading4:
        "font-HelveticaNeueRegular py-[10px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[500] w-full text-center bg-fonziYellow",
    paragraph: "paragraph text-fonziRed font-HelveticaNeueRegular font-[600] text-[15px] mb-[20px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "linklink",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink:
        "font-HelveticaNeueRegular text-[14px] custom-line-height font-[700] uppercase border border-[1px] border-fonziRed py-[9px] px-[19px] rounded-[17px] text-center text-fonziRed text-decoration-none transition duration-300 ease-in-out hover:bg-fonziRed hover:text-fonziYellow",
    tableCls: "mb-[32px]",
}

export const TWO_COLUMN_TEXT = {
    richTextWrapper: "rich-wrapper max-w-[800px] laptop:px-[40px] md:px-[20px] z-[3]",
    title: "font-HelveticaNeueBold custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[82px] laptop:text-[54px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph text-center product-details-paragraph text-fonziRed font-HelveticaNeueRegular font-[600] text-[19px] sm:text-[16px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const ABOUT_SECTION = {
    richTextWrapper: "rich-wrapper max-w-[460px] md:px-[20px] z-[3] pb-[140px]",
    title: "font-HelveticaNeueBold title-text-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[82px] md:text-[54px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[30px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph py-[40px] px-[10px] text-center custom-line-height product-details-paragraph text-white font-HelveticaNeueRegular font-[700] text-[36px] md:text-[28px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const TIMELINE_TEXT = {
    richTextWrapper: "rich-wrapper relative m-auto max-w-[575px] z-[3]",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[82px] l:text-[54px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[30px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph pt-[20px] pb-[40px] px-[10px] text-center custom-line-height product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[19px] l:text-[16px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const PRIVACY_NOTICE = {
    richTextWrapper: "rich-wrapper max-w-full z-[3] px-[10px]",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[82px] lg:text-[60px] py-[55px]",
    code: "",
    heading2: "font-HelveticaNeueBold mt-[40px] text-fonziRed text-[24px]",
    heading3: "font-HelveticaNeueBold mt-[40px] text-fonziRed text-[19px]",
    heading4:
        "font-HelveticaNeueRegular mt-[40px] custom-line-height text-[16px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] ",
    paragraph:
        "paragraph py-[20px] font-[600] text-left product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[16px] tracking-[1px]",
    list: "list flex w-full py-[10px]  md:pl-[10px] font-HelveticaNeueRegular marker:text-[20px] marker:text-black text-fonziRed text-[16px]",
    ulList: "listui flex flex-wrap my-[20px]",
    listChild: "list-child w-[95%] ml-[20px] md:text-[12px]",
    listStyle: "flex justify-center items-center w-[8px] h-[8px] mt-[6px] rounded-[60px] bg-fonziRed ",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink:
        "mainLink bg-fonziRed text-white py-[16px] px-[20px] flex justify-center w-fit items-center rounded-[0.25em]",
    tableCls: "mb-[32px]",
}

export const SECTION_TEXT = {
    richTextWrapper: "rich-wrapper max-w-full z-[3] px-[10px] sm:px-0 mb-[100px] md:mb-[20px]",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow text-[54px] sm:text-[45px]",
    code: "",
    heading2: "font-HelveticaNeueBold mt-[40px] text-fonziRed text-[24px]",
    heading3: "font-HelveticaNeueBold mt-[40px] text-fonziRed text-[19px]",
    heading4:
        "font-HelveticaNeueRegular mt-[40px] custom-line-height text-[16px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] ",
    heading5:
        "font-HelveticaNeueRegular mt-[25px] custom-line-height text-[36px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] text-center",
    paragraph:
        "paragraph py-[20px] text-center custom-line-height product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[36px] uppercase tracking-[1px]",
    list: "list flex w-full py-[10px]  md:pl-[10px] font-HelveticaNeueRegular marker:text-[20px] marker:text-black text-fonziRed text-[16px]",
    ulList: "listui flex flex-wrap my-[20px]",
    listChild: "list-child w-[95%] ml-[20px] md:text-[12px]",
    listStyle: "flex justify-center items-center w-[8px] h-[8px] mt-[6px] rounded-[60px] bg-fonziRed ",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink:
        "mainLink bg-fonziRed text-white py-[16px] px-[20px] flex justify-center w-fit items-center rounded-[0.25em]",
    tableCls: "mb-[32px]",
}

export const TWO_COLUMN_RED_TEXT = {
    richTextWrapper: "rich-wrapper flex-col flex justify-center h-full z-[3] sm:p-[20px] sm:py-[40px]",
    title: "font-HelveticaNeueBold custom-line-height text-fonziRed line-height-[1px] tracking-[1px] w-full banner-title-text-shadow text-[72px] l:text-[50px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[30px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph mt-[20px] product-details-paragraph text-fonziRed font-HelveticaNeueRegular font-[600] text-[19px] l:text-[16px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const YOUTUBE_SLIDESHOW_TEXT = {
    richTextWrapper: "rich-wrapper flex-col flex justify-center h-full z-[3]",
    title: "font-HelveticaNeueBold custom-line-height text-center text-white line-height-[1px] tracking-[1px] w-full text-[50px] pt-[50px]",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[30px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph mt-[20px] product-details-paragraph text-fonziRed font-HelveticaNeueRegular font-[600] text-[16px] tracking-[1px]",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const INTERACTIVE_SLIDE_TEXT = {
    richTextWrapper:
        "rich-wrapper pl-[60px] lg:pl-0 flex-col flex justify-center h-full z-[3] pb-[200px] lg:pb-[100px] sm:p-[20px]",
    title: "font-HelveticaNeueBold custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-[82px] l:text-[54px] slide-title-shadow sm:text-center",
    code: "",
    heading4:
        "font-HelveticaNeueRegular custom-line-height text-[36px] py-[30px] px-[20px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] w-full text-center ",
    paragraph:
        "paragraph mt-[20px] product-details-paragraph text-fonziRed font-HelveticaNeueRegular font-[600] text-[19px] l:text-[16px] tracking-[1px] sm:text-center",
    list: "list ",
    ulList: "listui",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "mainLink buttonEffect2 text-white",
    tableCls: "mb-[32px]",
}

export const ERROR_PAGE = {
    richTextWrapper: "rich-wrapper max-w-full z-[3] px-[10px] py-[40px] lg:py-[80px] text-center",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow lg:text-[82px] text-[142px] py-[55px]",
    code: "",
    heading2: "font-HelveticaNeueBold mt-0 text-fonziRed lg:text-[28px] text-[42px]",
    heading3: "font-HelveticaNeueBold mt-0 text-fonziRed text-[19px]",
    heading4:
        "font-HelveticaNeueRegular mt-[40px] custom-line-height text-[16px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] ",
    paragraph:
        "paragraph py-[20px] font-[600] text-left product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[16px] tracking-[1px]",
    list: "list flex w-full py-[10px]  md:pl-[10px] font-HelveticaNeueRegular marker:text-[20px] marker:text-black text-fonziRed text-[16px]",
    ulList: "listui flex flex-wrap my-[20px]",
    listChild: "list-child w-[95%] ml-[20px] md:text-[12px]",
    listStyle: "flex justify-center items-center w-[8px] h-[8px] mt-[6px] rounded-[60px] bg-fonziRed ",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink:
        "mainLink mx-auto bg-fonziRed text-white py-[16px] px-[20px] flex justify-center w-fit items-center rounded-[0.25em]",
    tableCls: "mb-[32px]",
}

export const CORPORATE_DATA_TEXT = {
    richTextWrapper: "rich-wrapper max-w-[70%] mid:max-w-full mid:m-auto z-[3] px-[10px] py-[40px] text-center",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-left banner-title-text-shadow text-[66px] py-[55px]",
    code: "",
    heading2: "font-HelveticaNeueBold mt-0 text-fonziRed lg:text-[28px] text-[42px]",
    heading3: "font-HelveticaNeueBold mt-0 text-fonziRed text-[19px]",
    heading4:
        "font-HelveticaNeueRegular mt-[40px] custom-line-height text-[16px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] ",
    paragraph:
        "paragraph font-HelveticaNeueBold mid:text-center text-left product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[16px] tracking-[1px]",
    list: "list flex w-full py-[10px]  md:pl-[10px] font-HelveticaNeuebold marker:text-[20px] marker:text-black text-fonziRed text-[18px]",
    ulList: "listui flex flex-wrap my-[20px]",
    listChild: "list-child w-[95%] ml-[20px] md:text-[12px]",
    listStyle: "flex justify-center items-center w-[8px] h-[8px] mt-[6px] rounded-[60px] bg-fonziRed ",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink:
        "mainLink mx-auto bg-fonziRed text-white py-[16px] px-[20px] flex justify-center w-fit items-center rounded-[0.25em]",
    tableCls: "mb-[32px]",
}

export const CORPORATE_DATA_SIDEBAR_TEXT = {
    richTextWrapper: "rich-wrapper max-w-[30%] mid:max-w-full mid:m-auto z-[3] p-[40px] md:mt-[40px] text-center",
    title: "font-HelveticaNeueBold slide-title-shadow custom-line-height text-white line-height-[1px] tracking-[1px] w-full text-center banner-title-text-shadow lg:text-[82px] text-[142px] py-[55px]",
    code: "",
    heading2: "font-HelveticaNeueBold mt-0 text-fonziRed lg:text-[28px] text-[20px]",
    heading3: "font-HelveticaNeueBold mt-0 text-fonziRed text-[19px]",
    heading4:
        "font-HelveticaNeueRegular mt-[40px] custom-line-height text-[16px] text-fonziRed line-height-[1px] tracking-[1px] font-[700] ",
    paragraph:
        "paragraph py-[20px] font-[600]  text-left product-details-paragraph text-fonziRed font-[700] font-HelveticaNeueRegular font-[700] text-[16px] tracking-[1px]",
    list: "list flex w-full py-[10px]  md:pl-[10px] font-HelveticaNeueRegular marker:text-[20px] marker:text-black text-fonziRed text-[16px]",
    ulList: "listui flex flex-wrap my-[20px]",
    listChild: "list-child w-[95%] ml-[20px] md:text-[12px]",
    listStyle: "flex justify-center items-center w-[8px] h-[8px] mt-[6px] rounded-[60px] bg-fonziRed ",
    mainLink: "",
    link: "",
    table: "product-details-table w-full",
    tRow: "rounded-[0px]",
    tHeader: "th tracking-[1px]",
    tData: "data",
    mainLink: "w-full flex justify-center",
    tableCls: "mb-[32px]",
}
