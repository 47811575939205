export const defaultStyle = {
    socialWpr: "footerlink flex text-center text-white flex justify-center py-20",
    socialIcon: "px-10",
    externalLink: "test2",
    captionTxt: "text-[#8d8d8d] text-sm",
    socialImg: "",
}
export const INLINEFOOTER = {
    socialWpr: "footerlink flex text-center text-white flex justify-center py-20",
    socialIcon: "px-10",
    externalLink: "test2",
    captionTxt: "",
    socialImg: "",
}

export const socialLinks = {
    socialWpr: "footerlink flex text-center text-white flex justify-center py-20",
    socialIcon: "mx-20 lg:odd:w-[38px] lg:even:w-[24px] odd:w-[50px] even:w-[30px]  h-auto",
    externalLink: "",
    captionTxt: "",
    socialImg: "background:new 0 0 152.8 152.5 w-24 h-24",
}

export const socialIcon = {
    socialWpr: "footerlink flex text-center text-white flex justify-center py-20 w-[40px]",
    socialIcon: "px-10",
    externalLink: "test2",
    captionTxt: "text-[#8d8d8d] text-15 text-center leading-[21px] mt-10",
    socialImg: "mx-auto",
}
export const socialIconVar = {
    socialIcon: "px-10 w-[65px] text-center m-5 flex flex-wrap justify-center",
    socialWpr: "flex justify-center mb-10 gap-[5px] lg:gap-0",
    externalLink: "",
    iconBtnWpr: "text-center mx-auto my-0 relative w-[214px] text-xl leading-4 mb-5 font-bold",
    iconBtnimg: "absolute right-[-28px] top-[-16px]",
    btnImgwpr: "flex justify-center",
    btnImg: "mx-auto",
    icontxt: "text-[#8d8d8d] text-15 text-center leading-[21px] mt-10",
    iconBtn: "px-10",
    socialImg: "mx-auto",
}

export const formSocialIcon = {
    socialWpr: "my-30 pt-40",
    socialIcon: "text-center mx-auto text-white w-[48px]",
}
