import * as React from "react"
import PropTypes from "prop-types"
import { convertToWebp, getImageData, transformImage } from "../../../helpers/ImageHelper"
import { get } from "../../../helpers/utils"

const Image = props => {
    const {
        contentfulImage,
        src,
        className,
        alt,
        additionalParams,
        onClick = () => undefined,
        width = undefined,
        height = undefined,
        eventClass,
        eventLabel,
    } = props

    if (!contentfulImage && !src) return null

    // create image object from contentful Image object
    const cImgObject = contentfulImage && getImageData(contentfulImage, additionalParams)
    const classNameFinal = [className, eventClass].join(" ")

    // assign src, alt, w, h from either contentful Image object or direct params passed
    const imgSrc = cImgObject?.url || transformImage(src, additionalParams)
    const imgAlt = cImgObject?.alt || alt
    const imgW = width || get(cImgObject, "width")
    const imgH = height || get(cImgObject, "height")

    return (
        <>
            <img
                role="presentation" // for accessibility
                src={convertToWebp(imgSrc)}
                alt={imgAlt}
                className={classNameFinal}
                onClick={onClick}
                width={imgW}
                height={imgH}
                data-action-detail={eventLabel}
            />
        </>
    )
}

export default Image

Image.propTypes = {
    contentfulImage: PropTypes.object,
    src: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
    additionalParams: PropTypes.object,
    onClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.number,
    eventClass: PropTypes.string,
    eventLabel: PropTypes.string,
}
