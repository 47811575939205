import React from "react"
import PropTypes from "prop-types"
import * as styles from "./PolicySection-tw-styles"
import Image from "../UI/Image/Image"
import RichTextModel from "../RichTextModel/RichTextModel"
import DangerousHTML from "../DangerousHtml/DangerousHtml"
import { toCamelCase } from "../../helpers/utils"

const PolicySection = props => {
    const { headerImage, title, text, sidebarText, variant } = props
    const styleVariant = toCamelCase(variant)
    const style = styles?.[styleVariant] || styles.defaultStyle
    return (
        <div className={`${style?.policySection}`}>
            <div className={style?.policySectionHeader}>
                <Image className={style?.policyHero} contentfulImage={headerImage} />
            </div>
            <div className={style?.policySectionWrapper}>
                {title && <h1 className={style?.policySectionTitle}>{title}</h1>}
                {sidebarText && <RichTextModel {...sidebarText} />}
                {styleVariant === "cookiePolicyContent" ? <DangerousHTML content={text?.content}/> : <RichTextModel {...text} />}
            </div>
        </div>
    )
}

export default PolicySection

PolicySection.propTypes = {
    headerImage: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.object,
    sidebarText: PropTypes.object,
    variant: PropTypes.string,
}
