import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as styles from "./ContestSection-tw-styles"
import { toCamelCase } from "../../helpers/utils"
import ContestCard from "../UI/ContestCard/ContestCard"

const ContestSection = props => {
    const { contestsCollection, backgroundColor } = props

    const [visibleCardCount, setVisibleCardCount] = useState(2)

    const handleScroll = () => {
        const windowHeight = window.innerHeight
        const scrollHeight = document.documentElement.scrollHeight
        const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop || 0

        if (scrollHeight - (scrollTop + windowHeight) < 300) {
            setVisibleCardCount(prevCount => prevCount + 2)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const style = styles.defaultStyle

    const bgColor = toCamelCase(backgroundColor)

    const [rePosition, setRePosition] = useState(false)

    useEffect(() => {
        const delay = 500
        setTimeout(() => {
            setRePosition(true);
        }, delay)
    }, [])


    return (
        <div className={`${style?.contestSection} ${rePosition ? "contest-section-animation" : ""} ${style?.backgroundColor[bgColor]}`}>
            <div className={style?.contestSectionWrapper}>
                <h2 className={style?.contestSectionTitle}>Concorsi</h2>
                <div className={style?.contestSectionContainer}>
                    {contestsCollection?.items.slice(0, visibleCardCount).map((item, index) => (
                        <ContestCard {...item} key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ContestSection

ContestSection.propTypes = {
    contestsCollection: PropTypes.object,
    backgroundColor: PropTypes.string,
}
