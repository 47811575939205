import React, { useEffect, useState } from "react"
import Link from "../../../../../UI/Link/Link"
import PropTypes from "prop-types"
import { deslashify } from "../../../../../../helpers/utils"
import { gaEventClasses } from "../../../../../../constants/gtm.constants"

const NavigationItem = props => {
    const { item } = props
    const { label, link } = item

    const [activeLinkClass, setActiveLinkClass] = useState("")

    useEffect(() => {
        let currentPath = ""
        if (typeof window !== "undefined") {
            currentPath = window?.location?.pathname
            const isActive = deslashify(currentPath) === deslashify(link?.url)
            if (isActive) {
                setActiveLinkClass("lightBlueText")
            }
        }
    }, [])

    return (
        <li className="nav-item relative z-[3] mobile-nav-item text-shadow w-full font-HelveticaNeueBold text-[42px] text-white sm:pt-[28px] pt-[42px">
            {link ? (
                <Link
                    {...link}
                    className={`active:text-lighteshtBlue hover:text-lighteshtBlue ${activeLinkClass}`}
                    gaEventClass={gaEventClasses.menu_click}
                    gaEventLabel={label}
                >
                    {label}
                </Link>
            ) : (
                label
            )}
        </li>
    )
}

NavigationItem.propTypes = {
    item: PropTypes.shape({
        link: PropTypes.object,
        label: PropTypes.string,
    }),
}

export default NavigationItem
