import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import RichTextModel from "../RichTextModel/RichTextModel"
import Image from "../UI/Image/Image"
import * as styles from "./TwoColumnSection-tw-styles.js"
import { toCamelCase } from "../../helpers/utils"
import IconElement from "../UI/IconElement/IconElement"
import { gaEventClasses } from "../../constants/gtm.constants"

const TwoColumnSection = props => {
    const { firstColumnCollection, secondColumnCollection, variation, scrollToContent, firstSection, backgroundColor } =
        props

    const style = styles[toCamelCase(variation)] || styles.defaultStyle

    const variationStyle = toCamelCase(variation)

    const wrapperRef = useRef(null)
    const copiedElementRef = useRef(null)
    const videoRef = useRef(null)

    const getWindowWidth = () => {
        return typeof window !== "undefined" ? window.innerWidth : 0
    }

    const [firstImageHasImage, setFirstColumnHasImage] = useState(false)

    const [isVideoPlaying, setIsVideoPlaying] = useState(false)

    const isFirstColumnImage = item => item?.__typename === "MediaElement"

    const bgColor = toCamelCase(backgroundColor)

    const scrollToNextComponent = () => {
        const currentComponentNode = wrapperRef.current
        if (currentComponentNode && currentComponentNode.nextElementSibling) {
            currentComponentNode.nextElementSibling.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(() => {
        if (variationStyle === "homepageNarrow") {
            const hasFirstImage = firstColumnCollection?.items.some(isFirstColumnImage)
            setFirstColumnHasImage(hasFirstImage)
        }
    }, [])

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (windowWidth !== 0 && windowWidth <= 425) {
            const videoElements = document.getElementsByTagName("video")

            for (let i = 0; i < videoElements.length; i++) {
                videoElements[i].load()
            }
        }
    }, [])

    const additionalStyles = "min-h-[480px] lg:min-h-[280px] m-auto pb-[100px] sm:p-[0px]"

    const [rePosition, setRePosition] = useState(false)

    useEffect(() => {
        const delay = 500
        setTimeout(() => {
            setRePosition(true)
        }, delay)
    }, [])

    const [isInSectionViewport, setIsInSectionViewport] = useState(false)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsInSectionViewport(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (wrapperRef.current) {
            observer.observe(wrapperRef.current)
        }

        // Cleanup function
        return () => {
            if (wrapperRef.current) {
                observer.unobserve(wrapperRef.current)
            }
        }
    }, [])

    return (
        <div
            className={`${style?.twoColumn} ${
                variationStyle === "default" && rePosition
                    ? "two-column-reposition-animation"
                    : `two-column-${variationStyle}`
            } ${firstSection ? style?.firstSection : ""} ${
                bgColor !== "transparent" ? style?.backgroundColor[bgColor] : ""
            } ${variationStyle === "homepageNarrow" && isInSectionViewport ? "homepage-narrow-animation" : ""}`}
            ref={wrapperRef}
        >
            <div
                className={`${style?.twoColumnWrapper} ${variationStyle === "homepageNarrow" ? additionalStyles : ""}`}
            >
                <div
                    className={`${style?.twoColumnFirst} ${
                        firstImageHasImage ? style?.firstColHasMedia : style?.firstColHasNoMedia
                    } ${
                        variationStyle === "homepageNarrow" &&
                        (windowWidth < 426 || windowWidth === 0) &&
                        !firstImageHasImage
                            ? "order-2"
                            : ""
                    } ${!firstImageHasImage ? "text-col" : ""} ${
                        variationStyle === "homepageFull" && !firstImageHasImage && isInSectionViewport
                            ? "homepage-full-animatation"
                            : ""
                    }
`}
                >
                    {firstColumnCollection?.items.map((item, index) => {
                        switch (item?.__typename) {
                            case "MediaElement":
                                if (item?.media?.url) {
                                    const isVideo = item?.media?.url.endsWith(".mp4")
                                    if (isVideo) {
                                        return (
                                            <div
                                                ref={copiedElementRef}
                                                className={`${style?.twoColumnVideo} ${gaEventClasses.video_play}`}
                                                data-action-detail="video"
                                                key={index}
                                            >
                                                <video
                                                    ref={videoRef}
                                                    controls={false}
                                                    onPlay={() => setIsVideoPlaying(true)}
                                                    onPause={() => setIsVideoPlaying(false)}
                                                    muted
                                                    loop
                                                    autoPlay
                                                    playsInline
                                                    preload="none"
                                                >
                                                    <source
                                                        src={`${item?.media?.url}#t=0.1`}
                                                        type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                                {!isVideoPlaying && (
                                                    <div
                                                        className={style?.playButtonOverlay}
                                                        onClick={() => {
                                                            setIsVideoPlaying(true)
                                                            if (videoRef.current) {
                                                                videoRef.current.play() // Play the video
                                                            }
                                                        }}
                                                    >
                                                        <svg
                                                            className={style?.playButtonSvg}
                                                            id="icon-play-only"
                                                            viewBox="0 0 71 71"
                                                            enableBackground="new 0 0 71 71"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M45.5,35.5l-16,12v-24Z"
                                                                fill="#fff"
                                                                fillRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div ref={copiedElementRef} className={style?.twoColumnImage} key={index}>
                                                <Image contentfulImage={item?.media} />
                                            </div>
                                        )
                                    }
                                }
                                return null
                            case "IconElement":
                                return <IconElement {...item} />
                            case "RichTextModel":
                                return (
                                    <div
                                        className={`${style?.twoColumnText} ${
                                            variationStyle === "homepageNarrow" ? style?.narrowLeftStyle : ""
                                        }`}
                                        key={index}
                                    >
                                        <RichTextModel {...item} />
                                        {variationStyle !== "homepageNarrow" && (
                                            <svg
                                                className={style?.behindTextSvg}
                                                id="fonzies-shape"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 214 292"
                                            >
                                                <path
                                                    fill="#F7D200"
                                                    d="M116.2 282.2c3.7 2 7.4 4.3 11.3 6.1 1.4.6 8.9 5.6 8.7 2.8-11.4-19-26.9-41.1-20.3-64.4 12.1-37.2 79.3-43.1 94.4-86.4 16.5-46.8-23.1-88.5-59.1-111.9-8.5-5.6-16.6-10.5-25.4-15.1-3.6-1.5-36-19.3-30.1-10.6 7.3 6.8 14.6 17.6 13 27.9-2.8 22-42.7 31.6-60 41.6C35.1 79.1 22.5 89 14 101.8c-10.1 15-15.5 34.7-14 53.1 4 41.8 36.3 74.4 69.2 97.2 15.3 10.6 30.8 20.9 47 30.1"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                )
                            default:
                                return null
                        }
                    })}
                </div>
                <div
                    className={`${style?.twoColumnSecond} ${
                        firstImageHasImage ? style?.secondColHasNoMedia : style?.secondColHasMedia
                    } ${firstImageHasImage ? "text-col" : ""} ${
                        variationStyle === "homepageFull" && firstImageHasImage && isInSectionViewport
                            ? "homepage-full-animatation"
                            : ""
                    }`}
                >
                    {secondColumnCollection?.items.map((item, index) => {
                        switch (item?.__typename) {
                            case "MediaElement":
                                if (item?.media?.url) {
                                    const isVideo = item?.media?.url.endsWith(".mp4")
                                    if (isVideo) {
                                        return (
                                            <div ref={copiedElementRef} className={style?.twoColumnVideo} key={index}>
                                                <video
                                                    ref={videoRef}
                                                    controls={false}
                                                    onPlay={() => setIsVideoPlaying(true)}
                                                    onPause={() => setIsVideoPlaying(false)}
                                                    muted
                                                    loop
                                                    autoPlay
                                                    playsInline
                                                    preload="none"
                                                >
                                                    <source
                                                        src={`${item?.media?.url}#t=0.1`}
                                                        type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                                {!isVideoPlaying && (
                                                    <div
                                                        className={style?.playButtonOverlay}
                                                        onClick={() => {
                                                            setIsVideoPlaying(true)
                                                            if (videoRef.current) {
                                                                videoRef.current.play() // Play the video
                                                            }
                                                        }}
                                                    >
                                                        <svg
                                                            className={style?.playButtonSvg}
                                                            id="icon-play-only"
                                                            viewBox="0 0 71 71"
                                                            enableBackground="new 0 0 71 71"
                                                            preserveAspectRatio="xMidYMid meet"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M45.5,35.5l-16,12v-24Z"
                                                                fill="#fff"
                                                                fillRule="evenodd"
                                                            />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div ref={copiedElementRef} className={style?.twoColumnImage} key={index}>
                                                <Image contentfulImage={item?.media} />
                                            </div>
                                        )
                                    }
                                }
                                return null
                            case "IconElement":
                                return <IconElement {...item} />
                            case "RichTextModel":
                                return (
                                    <div
                                        className={`${style?.twoColumnText} ${
                                            variationStyle === "homepageNarrow" ? style?.narrowRightStyle : ""
                                        }`}
                                        key={index}
                                    >
                                        <RichTextModel {...item} />
                                        {variationStyle !== "homepageNarrow" && (
                                            <svg
                                                className={style?.behindTextSvg}
                                                id="fonzies-shape"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 214 292"
                                            >
                                                <path
                                                    fill="#F7D200"
                                                    d="M116.2 282.2c3.7 2 7.4 4.3 11.3 6.1 1.4.6 8.9 5.6 8.7 2.8-11.4-19-26.9-41.1-20.3-64.4 12.1-37.2 79.3-43.1 94.4-86.4 16.5-46.8-23.1-88.5-59.1-111.9-8.5-5.6-16.6-10.5-25.4-15.1-3.6-1.5-36-19.3-30.1-10.6 7.3 6.8 14.6 17.6 13 27.9-2.8 22-42.7 31.6-60 41.6C35.1 79.1 22.5 89 14 101.8c-10.1 15-15.5 34.7-14 53.1 4 41.8 36.3 74.4 69.2 97.2 15.3 10.6 30.8 20.9 47 30.1"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                )
                            default:
                                return null
                        }
                    })}
                </div>
            </div>
            {scrollToContent ? (
                <div className={style?.sectionScrollBox}>
                    <div
                        className={`${style?.sectionScrollBoxWrapper} ${gaEventClasses.button_click}`}
                        data-action-detail={"scroll"}
                    >
                        <svg
                            className={style?.sectionScrollSvg}
                            width="84"
                            height="90"
                            viewBox="0 0 84 90"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M40.288 90.0004C58.2188 90.0004 83.043 53.8619 83.043 25.3091C83.043 -3.2436 63.065 -1.39415 41.999 1.67178C41.5045 1.74102 29.3199 5.16299 28.9441 5.46959C28.9441 5.46959 0.955078 11.6113 0.955078 34.7344C0.955078 58.352 22.3672 90.0004 40.288 90.0004Z"
                                fill="#CC202C"
                            />
                        </svg>
                        <div className={style?.scrollWrapper} onClick={scrollToNextComponent}>
                            <div className={style?.scrollText}>Scroll</div>
                            <svg
                                className={style?.downSvg}
                                width="19"
                                height="12"
                                viewBox="0 0 19 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.15751 3.2638L8.3674 10.6814C8.51184 10.8375 8.687 10.962 8.88188 11.0472C9.07676 11.1324 9.28714 11.1764 9.49982 11.1764C9.71249 11.1764 9.92288 11.1324 10.1178 11.0472C10.3126 10.962 10.4878 10.8375 10.6322 10.6814L17.8421 3.2638C18.0024 3.12772 18.1334 2.9605 18.2272 2.77228C18.3209 2.58406 18.3754 2.37875 18.3875 2.16882C18.3995 1.95889 18.3687 1.74871 18.2971 1.55102C18.2255 1.35332 18.1144 1.17224 17.9707 1.01875L17.7136 0.741823C17.13 0.227537 16.4476 0.0989663 15.9432 0.672593C14.1729 2.50226 12.3927 4.31216 10.6223 6.14183C10.478 6.29636 10.3034 6.41956 10.1095 6.50379C9.91552 6.58803 9.70632 6.63149 9.49487 6.63149C9.28341 6.63149 9.07421 6.58803 8.88026 6.50379C8.68631 6.41956 8.51174 6.29636 8.3674 6.14183C6.59707 4.32205 4.81685 2.50226 3.04652 0.672593C2.9321 0.55093 2.7929 0.455243 2.63833 0.391996C2.48375 0.328749 2.3174 0.299421 2.15052 0.305984C1.98364 0.312547 1.8201 0.354851 1.67097 0.430041C1.52184 0.505231 1.39059 0.611551 1.28608 0.741823L1.01905 1.01875C0.874584 1.17222 0.763098 1.35365 0.691463 1.55187C0.619828 1.75008 0.589561 1.96087 0.602535 2.17123C0.615509 2.38159 0.671447 2.58707 0.766896 2.77498C0.862345 2.96289 0.995282 3.12925 1.15751 3.2638Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default TwoColumnSection

TwoColumnSection.propTypes = {
    firstColumnCollection: PropTypes.object,
    secondColumnCollection: PropTypes.object,
    variation: PropTypes.string,
    scrollToContent: PropTypes.bool,
    firstSection: PropTypes.bool,
    backgroundColor: PropTypes.string,
}
