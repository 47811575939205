export const defaultStyle = {
    modalwrpr: "bg-white flex justify-center items-center w-full h-full fixed top-0 left-0 z-10 opacity-[0.7]",
}

export const textPopup = {
    modalwrpr: "bg-black bg-opacity-[0.65] flex justify-center items-center w-full h-full fixed top-0 left-0 z-[10]",
    modalContainer: "bg-white text-lg leading-4 overflow-hidden p-30 absolute w-[600px] max-w-[80%] rounded-[30px]",
    closeBtn: "absolute right-20 top-20 w-20 h-20",
}

export const fullScreenMenu = {
    modalwrpr: "bg-lightGray flex justify-center items-center w-full h-full fixed top-0 left-0 z-10 ",
    modalContainer:
        "bg-[#36236a] text-lg leading-4 overflow-hidden p-30 absolute  w-full max-w-[100%] rounded-[30px]  h-full text-white",
    closeBtn: "absolute right-20 top-20",
}

export const RECIPE_LIST_FILTER = {
    modalwrpr:
        "bg-lightBlueBGClr flex justify-center items-center w-full h-full top-0 left-0 z-10 px-20 lg:px-auto absolute",
    modalContainer: "modalContainer absolute top-[50px]",
}
