import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as styles from "./IconElement-tw-styles"
import Image from "../Image/Image"
import { gaEventClasses } from "../../../constants/gtm.constants"

const IconElement = props => {
    const { image, link } = props

    const style = styles.defaultStyle

    function scrollToElement(elementId) {
        const element = document.getElementById(elementId)

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            })
        }
    }

    return (
        <div
            className={`${style?.iconElement} ${gaEventClasses.button_click}`}
            data-action-detail="scroll"
            onClick={() => {
                scrollToElement(link?.scrollToElementId)
            }}
        >
            <div className={style?.iconElementLink}>
                <span dangerouslySetInnerHTML={{ __html: link?.label }} />
            </div>
            <div className={style?.iconElementImage}>
                <Image className={style?.iconImage} contentfulImage={image} />
            </div>
        </div>
    )
}

export default IconElement

IconElement.propTypes = {
    image: PropTypes.object,
    link: PropTypes.object,
}
