const query = (id, isPreview) => `
{
    policySection (id: "${id}", preview: ${isPreview}) {
        headerImage {
            url
        }
        title
        variant
        text {
            ... on DangerousHtml {
                content
            }
            ... on RichTextModel {
                name
                variant
                title
                richText{
                    json
                    links {
                        entries {
                          inline {
                            sys{
                              id
                            }
                            ... on Link {
                              label
                              url
                              target
                            }
                          }
                          block {
                            __typename
                            sys{
                              id
                            }
                            ... on DangerousHtml {
                              content
                            }
                          }
                        }
                      }
                }
                link {
                    label
                    title
                    url
                    target
                    linkStyle
                    scrollToElementId
                    gaEventClass
                    gaEventLabel
                }
            }
        }
        sidebarText {
          ... on RichTextModel {
              name
              variant
              title
              richText{
                  json
                  links {
                      entries {
                        inline {
                          sys{
                            id
                          }
                          ... on Link {
                            label
                            url
                            target
                          }
                        }
                        block {
                          __typename
                          sys{
                            id
                          }
                          ... on DangerousHtml {
                            content
                          }
                        }
                      }
                    }
              }
              link {
                  label
                  title
                  url
                  target
                  linkStyle
                  scrollToElementId
                  gaEventClass
                  gaEventLabel
                  image {
                    url
                  }
              }
          }
        
      }
    }
}
`

module.exports = { query }
