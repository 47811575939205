const query = (id, isPreview) => `
{
    contestSection (id: "${id}", preview: ${isPreview}) {
        contestsCollection(limit: 20) {
            items {
                image {
                    url
                }
                text {
                    ... on RichTextModel {
                        name
                        variant
                        title
                        richText{
                            json
                        }
                        link {
                            label
                            title
                            url
                            target
                            linkStyle
                            scrollToElementId
                            gaEventClass
                            gaEventLabel
                        }
                    }
                }
                terminated
            }
        }
        backgroundColor
    }
}
`

module.exports = { query }
