export const defaultStyle = {
    contestSection: "contest-section",
    contestSectionWrapper: "max-w-[1200px] m-auto relative",
    contestSectionTitle: "font-HelveticaNeueRegular right-[60px] sm:right-[30px] font-[700] top-[60px] sm:top-[15px] text-[82px] l:text-[59px] absolute text-fonziYellow",
    contestSectionContainer: "contest-container py-[100px] flex flex-wrap justify-start m-auto",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
}
