const query = (id, isPreview) => `
{
    advancedSlideshowContainer (id: "${id}", preview: ${isPreview}) {
        pagination
        navigation
        loop
        slidesPerView
        slideshowStyle
        paginationColor
        firstSection
        slideshowItemsCollection(limit: 20) {
            items {
                __typename
                ... on ProductCard {
                    title
                    packSize
                    text {
                        ... on RichTextModel {
                            variant
                            title
                            richText{
                                json   
                            }
                            link {
                                label
                                title
                                url
                                linkStyle
                                target
                                scrollToElementId
                            }
                        }
                    }
                    details {
                        ... on RichTextModel {
                            variant
                            title
                            richText{
                                json   
                            }
                        }
                    }
                    image {
                        url
                    }
                    linkToContent
                    longLinkToContent
                }
                ... on SlideshowItemTimeline {
                    year
                    text {
                        ... on RichTextModel {
                            variant
                            title
                            richText{
                                json   
                            }
                            link {
                                label
                                title
                                url
                                linkStyle
                                target
                                scrollToElementId
                            }
                        }
                    }
                    image {
                        url
                    }
                    coverImage {
                        url
                    }
                    backgroundPattern
                    linkText
                    videoLink
                }
                ... on SlideshowItemYoutube {
                    youtubeLink
                }
                ... on SlideshowItemInteractiveImage {
                    image {
                        url
                    }
                    text {
                        ... on RichTextModel {
                            variant
                            title
                            richText{
                                json   
                            }
                            link {
                                label
                                title
                                url
                                target
                                scrollToElementId
                                gaEventClass
                                gaEventLabel
                              }
                        }
                    }
                    detailsCollection(limit: 3) {
                        items {
                            ... on RichTextModel {
                                variant
                                title
                                richText{
                                    json   
                                }
                            }
                        }
                    }
                    navigationImage {
                        url
                    }
                }
            }
        }
    }
}
`

module.exports = { query }
