import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import * as styles from "./DangerousHtml-tw-styles"

const DangerousHTML = props => {
    const { content } = props
    const style = styles.defaultStyles

    return <div className={style?.content}>{parse(content)}</div>
}

DangerousHTML.propTypes = {
    content: PropTypes.string,
}

export default DangerousHTML
