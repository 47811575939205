import { useEffect, useState } from "react"

const useScreenSize = () => {
    const SMALL_DESKTOP_BREAKPOINT = 1024
    const DESKTOP_BREAKPOINT = 992
    const TABLET_BREAKPOINT = 768

    const [width, setWidth] = useState(null)

    const handleResize = () => setWidth(window.innerWidth)

    useEffect(() => {
        if (typeof window !== "undefined") {
            // Add event listener
            window.addEventListener("resize", handleResize)

            // Call handler right away so state gets updated with initial window size
            handleResize()
            return () => {
                // Remove event listener on cleanup
                window.removeEventListener("resize", handleResize)
            }
        }
    }, []) // Empty array ensures that effect is only run on mount

    if (!width) {
        return null
    }

    return {
        width,
        isMobile: width < TABLET_BREAKPOINT,
        isTablet: width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT,
        isHandheld: width < DESKTOP_BREAKPOINT,
        isLargeHandheld: width > DESKTOP_BREAKPOINT && width <= SMALL_DESKTOP_BREAKPOINT,
        isDesktop: width >= DESKTOP_BREAKPOINT,
    }
}

export default useScreenSize
