import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as styles from "./ContestCard-tw-styles"
import Image from "../Image/Image"
import RichTextModel from "../../RichTextModel/RichTextModel"

const ContestCard = props => {
    const { image, text, terminated } = props

    const style = styles.defaultStyle

    const contestCardRef = useRef(null)

    const [isInSectionViewport, setIsInSectionViewport] = useState(false)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsInSectionViewport(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (contestCardRef.current) {
            observer.observe(contestCardRef.current)
        }

        // Cleanup function
        return () => {
            if (contestCardRef.current) {
                observer.unobserve(contestCardRef.current)
            }
        }
    }, [])

    return (
        <div
            className={`${style?.contestCard} ${isInSectionViewport ? "contest-card-animation" : ""}`}
            ref={contestCardRef}
        >
            <div className={style?.contestCardWrapper}>
                {terminated && <div className={style?.contestCardTerminated}></div>}
                <div className={style?.contestCardImageWrapper}>
                    <Image className={style?.contestCardImage} contentfulImage={image} />
                </div>
                <div className={style?.contestCardTextWrapper}>
                    <RichTextModel {...text} />
                </div>
            </div>
        </div>
    )
}

export default ContestCard

ContestCard.propTypes = {
    image: PropTypes.object,
    text: PropTypes.object,
    terminated: PropTypes.bool,
}
