const query = (id, isPreview) => `
{
    aboutSection (id: "${id}", preview: ${isPreview}) {
        text {
            ... on RichTextModel {
                variant
                title
                richText{
                    json
                    links {
                        entries {
                            inline {
                                sys{
                                    id
                                    }
                                ... on Link {
                                    label
                                    url
                                    linkStyle
                                    target
                                }
                            }
                        }
                    }    
                }
                link {
                    label
                    title
                    url
                    linkStyle
                    target
                    scrollToElementId
                }
            }
        }
        cardsCollection(limit: 10) {
            items {
                ... on AboutCard {
                    title
                    text
                }
            }
        }
        backgroundColor
    }
   }
`

module.exports = { query }
