import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import Image from "../UI/Image/Image"
import RichTextModel from "../RichTextModel/RichTextModel"
import Link from "../UI/Link/Link"
import { toCamelCase } from "../../helpers/utils"
import * as styles from "./BannerSection-tw-styles.js"
import { gaEventClasses } from "../../constants/gtm.constants"

const BannerSection = props => {
    const {
        text,
        backgroundImage,
        mobileBackgroundImage,
        backgroundColor,
        leftImage,
        rightImage,
        bannerMobileImage,
        variant,
        linkToContent,
        firstSection,
    } = props
    const leftContainerRef = useRef(null)
    const leftImageRef = useRef(null)
    const [leftnumImages, setLeftNumImages] = useState(1)

    const rightContainerRef = useRef(null)
    const rightImageRef = useRef(null)
    const [rightnumImages, setRightNumImages] = useState(1)

    const style = styles[toCamelCase(variant)] || styles.defaultStyle

    const styleVariant = toCamelCase(variant)

    const bgColor = toCamelCase(backgroundColor)

    const getWindowWidth = () => {
        return typeof window !== "undefined" ? window.innerWidth : 0
    }

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (leftContainerRef.current && leftImageRef.current) {
            const containerWidth = leftContainerRef.current.offsetWidth
            const imageWidth = leftImageRef.current.offsetWidth
            const calculatedNumImages = Math.floor(containerWidth / imageWidth)

            if (containerWidth / imageWidth < 1.2) {
                setLeftNumImages(1)
            } else {
                setLeftNumImages(calculatedNumImages + 1)
            }
        }
    }, [windowWidth])

    useEffect(() => {
        if (rightContainerRef.current && rightImageRef.current) {
            const containerWidth = rightContainerRef.current.offsetWidth
            const imageWidth = rightImageRef.current.offsetWidth
            const calculatedNumImages = Math.floor(containerWidth / imageWidth)

            if (containerWidth / imageWidth < 1.2) {
                setRightNumImages(1)
            } else {
                setRightNumImages(calculatedNumImages + 1)
            }
        }
    }, [windowWidth])

    const [rePosition, setRePosition] = useState(false)

    useEffect(() => {
        const delay = 500
        setTimeout(() => {
            setRePosition(true)
        }, delay)
    }, [])

    return (
        <div
            className={`${style?.bannerSection} ${firstSection ? style?.firstSection : ""} ${
                style?.backgroundColor[bgColor]
            }`}
        >
            {backgroundImage && windowWidth > 425 && (
                <>
                    {linkToContent ? (
                        <div className={style?.bannerSectionBgImage}>
                            <Link
                                url={linkToContent}
                                gaEventClass={gaEventClasses.image_click}
                                gaEventLabel="homepage banner video"
                            >
                                <Image className={style?.bannerBgImage} contentfulImage={backgroundImage} />
                            </Link>
                        </div>
                    ) : (
                        <div
                            className={`${style?.bannerSectionBgImage} ${gaEventClasses.banner_click}`}
                            data-action-detail="homepage banner video placeholder"
                        >
                            <Image className={style?.bannerBgImage} contentfulImage={backgroundImage} />
                        </div>
                    )}
                </>
            )}
            {mobileBackgroundImage && windowWidth <= 425 && windowWidth !== 0 && (
                <>
                    {linkToContent ? (
                        <div className={style?.bannerSectionBgImage}>
                            <Link url={linkToContent}>
                                <Image className={style?.bannerBgImage} contentfulImage={mobileBackgroundImage} />
                            </Link>
                        </div>
                    ) : (
                        <div className={style?.bannerSectionBgImage}>
                            <Image className={style?.bannerBgImage} contentfulImage={mobileBackgroundImage} />
                        </div>
                    )}
                </>
            )}
            {leftImage && windowWidth > 425 && (
                <div className={style?.bannerSectionLeftImage} ref={leftContainerRef}>
                    <div className={style?.leftImageWrapper}>
                        {Array.from({ length: leftnumImages }, (_, index) => (
                            <div className={style?.multiplyImage} key={`leftImage-${index}`} ref={leftImageRef}>
                                <Image className={style?.leftImage} contentfulImage={leftImage} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {text && (
                <div className={style?.bannerSectionMain}>
                    <div className={`${style?.bannerSectionText} ${rePosition ? "banner-text-animation" : ""}`}>
                        <RichTextModel {...text} />
                    </div>
                    <div className={style?.bannerSectionWatermark}>
                        <svg
                            className={style?.watermarkSvg}
                            id="fonzies-shape"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 214 292"
                        >
                            <path
                                fill="#f7d200"
                                d="M116.2 282.2c3.7 2 7.4 4.3 11.3 6.1 1.4.6 8.9 5.6 8.7 2.8-11.4-19-26.9-41.1-20.3-64.4 12.1-37.2 79.3-43.1 94.4-86.4 16.5-46.8-23.1-88.5-59.1-111.9-8.5-5.6-16.6-10.5-25.4-15.1-3.6-1.5-36-19.3-30.1-10.6 7.3 6.8 14.6 17.6 13 27.9-2.8 22-42.7 31.6-60 41.6C35.1 79.1 22.5 89 14 101.8c-10.1 15-15.5 34.7-14 53.1 4 41.8 36.3 74.4 69.2 97.2 15.3 10.6 30.8 20.9 47 30.1"
                            />
                        </svg>
                    </div>
                    {windowWidth <= 425 && (
                        <div className={style?.bannerMobileImageContainer}>
                            <Image className={style?.bannerMobileImage} contentfulImage={bannerMobileImage} />
                        </div>
                    )}
                </div>
            )}
            {rightImage && windowWidth > 425 && (
                <div className={style?.bannerSectionRightImage} ref={rightContainerRef}>
                    <div className={style?.rightImageWrapper}>
                        {Array.from({ length: rightnumImages }, (_, index) => (
                            <div className={style?.multiplyImage} key={`rightImage-${index}`} ref={rightImageRef}>
                                <Image className={style?.rightImage} contentfulImage={rightImage} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default BannerSection

BannerSection.propTypes = {
    text: PropTypes.object,
    backgroundImage: PropTypes.object,
    mobileBackgroundImage: PropTypes.object,
    backgroundColor: PropTypes.string,
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    bannerMobileImage: PropTypes.object,
    variant: PropTypes.string,
    linkToContent: PropTypes.string,
    firstSection: PropTypes.bool,
}
