const query = (id, isPreview) => `
{
    section (id: "${id}", preview: ${isPreview}) {
        backgroundColor
        variation
        firstSection
        contentCollection(limit: 6) {
            items {
                __typename
                ... on MediaElement {
                    media {
                    url
                  }
                  mobileMedia {
                    url
                  }
                }
                ... on AdvancedSlideshowContainer {
                    pagination
                    navigation
                    loop
                    slidesPerView
                    slideshowStyle
                    paginationColor
                    firstSection
                    slideshowItemsCollection(limit: 20) {
                    items {
                        __typename
                            ... on SlideshowItemYoutube {
                                youtubeLink
                            }
                            ... on SlideshowItemInteractiveImage {
                                image {
                                    url
                                }
                                text {
                                    ... on RichTextModel {
                                        variant
                                        title
                                        richText{
                                            json   
                                        }
                                        link {
                                            label
                                            title
                                            url
                                            target
                                            scrollToElementId
                                            gaEventClass
                                            gaEventLabel
                                          }
                                    }
                                }
                                detailsCollection(limit: 3) {
                                    items {
                                        ... on RichTextModel {
                                            variant
                                            title
                                            richText{
                                                json   
                                            }
                                        }
                                    }
                                }
                                navigationImage {
                                    url
                                }
                            }
                        }
                    }
                }
                ... on RichTextModel {
                    name
                    variant
                    title
                    richText{
                        json
                    }
                }
                ... on TwoColumnSection {
                    firstColumnCollection(limit: 5) {
                        items {
                            __typename
                            ... on MediaElement {
                              media {
                                url
                              }
                            }
                            ... on IconElement {
                                image {
                                    url
                                }
                                link {
                                    label
                                    title
                                    url
                                    target
                                    scrollToElementId
                                }
                            }
                            ... on RichTextModel {
                                variant
                                title
                                richText{
                                    json    
                                }
                            }
                        }
                    }
                    secondColumnCollection(limit: 5) {
                        items {
                            __typename
                            ... on MediaElement {
                                media {
                                url
                              }
                            }
                            ... on IconElement {
                                image {
                                    url
                                }
                                link {
                                    label
                                    title
                                    url
                                    target
                                    scrollToElementId
                                }
                            }
                            ... on RichTextModel {
                                variant
                                title
                                richText{
                                    json   
                                }
                            }
                        }
                    }
                    variation
                    scrollToContent
                    firstSection
                    backgroundColor
                }
            }
        }
    }
}
`

module.exports = { query }
