const query = (id, isPreview) => `
{
    bannerSection (id: "${id}", preview: ${isPreview}) {
        text {
            ... on RichTextModel {
                variant
                title
                richText{
                    json
                    links {
                        entries {
                            inline {
                                sys{
                                    id
                                    }
                                ... on Link {
                                    label
                                    url
                                    linkStyle
                                    target
                                }
                            }
                        }
                    }    
                }
                link {
                    label
                    title
                    url
                    linkStyle
                    target
                    scrollToElementId
                }
            }
        }
        leftImage {
            url
        }
        rightImage {
            url
        }
        bannerMobileImage {
            url
        }
        backgroundImage {
            url
        }
        mobileBackgroundImage {
            url
        }
        backgroundColor
        variant
        linkToContent
        firstSection
    }
   }
`

module.exports = { query }
