export const defaultStyle = {
    default: {
        firstSection: "",
        section: "section relative",
        wrapperDiv: "wrapper relative overflow-hidden",
        sectionContainer:
            "section-container overflow-hidden flex max-w-[1920px] m-auto sm:max-w-[360px] sm:p-[10px] sm:pt-[10px]",
        backgroundColor: {
            white: "bg-white",
            red: "bg-fonziRed",
            yellow: "bg-fonziYellow",
        },
        sectionLeftContainer: "section-left w-[30%] sm:mr-auto sm:w-[125px] pt-[60px]",
        sectionMainContainer:
            "section-main pt-[30px] pb-[30px] md:py-[20px] w-full z-10 flex flex-wrap relative mx-auto h-full justify-center",
        sectionMainText: " w-full m-auto sm:max-w-[360px] sm:p-[15px]",
        sectionRightContainer: "section-right w-[30%] sm:ml-auto sm:w-[125px] pt-[60px]",
        sectionBackgroundImageContainer: "section-bg absolute left-0 top-0 h-full z-0 w-full",
        sectionBackgroundImage: "object-cover h-full w-full",
        sectionScrollBox: "scroll-box absolute left-1/2 bottom-[60px] translate-x-[-30px] cursor-pointer",
        sectionScrollSvg: "scroll-bg-svg",
        mediaImageContainer: "m-auto mt-[40px] px-[120px] sm:px-[0px]",
        mediaImage: "",
    },
    parallax: {
        firstSection: "",
        section: "section relative overflow-hidden parallax-bg-img",
        wrapperDiv: "wrapper relative overflow-hidden z-[6]",
        sectionContainer: "section-container overflow-hidden flex max-w-[1920px] m-auto sm:max-w-[425px] sm:pt-[10px]",
        backgroundColor: {
            white: "bg-white",
            red: "bg-fonziRed",
            yellow: "bg-fonziYellow",
        },
        sectionLeftContainer: "section-left w-[30%] sm:mr-auto sm:w-[125px] pt-[60px]",
        sectionMainContainer:
            "section-main pt-[30px] pb-[30px] md:py-[20px] w-full z-10 flex flex-wrap relative mx-auto h-full justify-center",
        sectionMainText: " w-full m-auto sm:max-w-[360px] sm:p-[15px]",
        sectionRightContainer: "section-right w-[30%] sm:ml-auto sm:w-[125px] pt-[60px]",
        sectionBackgroundImageContainer: "section-bg absolute left-0 top-0 h-full z-0 w-full",
        sectionBackgroundImage: "object-cover h-full w-full",
        sectionScrollBox: "scroll-box absolute left-1/2 bottom-[60px] translate-x-[-30px] cursor-pointer",
        sectionScrollSvg: "scroll-bg-svg",
        mediaImageContainer: "max-w-[940px] m-auto mt-[40px]",
        mediaImage: "",
    },
    dots: {
        firstSection: "",
        section: "section relative overflow-hidden",
        wrapperDiv: "wrapper relative overflow-hidden z-[6]",
        sectionContainer:
            "section-container overflow-hidden flex max-w-[1920px] m-auto sm:max-w-full sm:p-[0px] sm:pt-[10px]",
        backgroundColor: {
            white: "bg-white",
            red: "bg-fonziRed",
            yellow: "bg-fonziYellow",
        },
        sectionLeftContainer: "section-left w-[30%] sm:mr-auto sm:w-[125px] pt-[60px]",
        sectionMainContainer:
            "section-main pt-[30px] pb-[30px] md:py-[20px] w-full z-10 flex flex-wrap relative mx-auto h-full justify-center",
        sectionMainText: " w-full m-auto sm:max-w-[360px] sm:p-[15px]",
        sectionRightContainer: "section-right w-[30%] sm:ml-auto sm:w-[125px] pt-[60px]",
        sectionBackgroundImageContainer: "section-bg absolute left-0 top-0 h-full z-0 w-full",
        sectionBackgroundImage: "object-cover h-full w-full",
        sectionScrollBox: "scroll-box absolute left-1/2 bottom-[60px] translate-x-[-30px] cursor-pointer",
        sectionScrollSvg: "scroll-bg-svg",
        mediaImageContainer: "max-w-[940px] m-auto mt-[40px]",
        mediaImage: "",
    },
    animated: {
        firstSection: "",
        section: "section section-animated-style relative",
        wrapperDiv: "wrapper relative overflow-hidden",
        sectionContainer:
            "section-container overflow-hidden flex max-w-[1920px] m-auto sm:p-[0px] sm:pt-[10px] sm:!pb-0",
        backgroundColor: {
            white: "bg-white",
            red: "bg-fonziRed",
            yellow: "bg-fonziYellow",
        },
        sectionLeftContainer: "section-left w-[30%] sm:mr-auto sm:w-[125px] pt-[60px]",
        sectionMainContainer:
            "section-main pt-[30px] pb-[30px] md:py-[20px] w-full z-10 flex flex-wrap relative mx-auto h-full justify-center sm:pb-0",
        sectionMainText: " w-full m-auto sm:max-w-[360px] sm:p-[15px]",
        sectionRightContainer: "section-right w-[30%] sm:ml-auto sm:w-[125px] pt-[60px]",
        sectionBackgroundImageContainer: "section-bg absolute left-0 top-0 h-full z-0 w-full",
        sectionBackgroundImage: "object-cover h-full w-full",
        sectionScrollBox: "scroll-box absolute left-1/2 bottom-[60px] translate-x-[-30px] cursor-pointer",
        sectionScrollSvg: "scroll-bg-svg",
        mediaImageContainer: "m-auto mt-[40px] px-[120px] sm:px-[0px]",
        mediaImage: "",
    },
}
