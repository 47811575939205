import React from "react"
import ListComponent from "../../UI/ListComponent/ListComponent"
import { get } from "../../../helpers/utils"
import PropTypes from "prop-types"
import * as styles from "./Footer-tw-styles"
import SocialLinks from "../../UI/SocialLinks/SocialLinks"
import Typography from "../../UI/Typography/Typography"
import Link from "../../UI/Link/Link"
import Image from "../../UI/Image/Image"
import useScreenSize from "../../../hooks/useScreenSize"
import { useGlobalContext } from "../../../context/GlobalContext"

const Footer = props => {
    const { note, mainLinksCollection, socialLinksCollection, brandLinksCollection } = props
    const labels = useGlobalContext().labels
    const style = styles.defaultStyle
    const {
        footerlinsMob,
        FooterHead,
        footerWrap,
        footerWrapper,
        fatFooter,
        copyrightContainer,
        footerSocialLinks,
        socialItem,
        socialSvg,
    } = style
    const screenSize = useScreenSize()

    const mainLinks = mainLinksCollection && get(mainLinksCollection, "items", [])
    const socialLinks = socialLinksCollection && get(socialLinksCollection, "items", [])
    const brandLinks = brandLinksCollection && get(brandLinksCollection, "items", [])
    return (
        <footer className={footerlinsMob}>
            <div className={footerWrapper}>
                {note && <Typography content={note} className={FooterHead} />}

                {/* social link component  */}
                {socialLinks && (
                    <div className={footerSocialLinks}>
                        {socialLinks.map((item, index) => {
                            return (
                                <Link
                                    className={socialItem}
                                    gaEventClass={item?.gaEventClass}
                                    url={item?.url}
                                    key={index}
                                >
                                    <Image className={socialSvg} contentfulImage={item?.image} />
                                </Link>
                            )
                        })}
                    </div>
                )}

                {/* Footer main content  */}
                <div className={footerWrap}>
                    <div className={fatFooter}>
                        {mainLinks?.length &&
                            mainLinks?.map((link, index) => {
                                const linksCollection = get(link, "linksCollection.items", [])
                                return (
                                    <ListComponent
                                        linksCollection={linksCollection}
                                        label={link.label}
                                        variant={"footerMainLinks"}
                                        key={index}
                                    />
                                )
                            })}
                    </div>
                </div>

                {/* site copyRight text */}
                <div className={copyrightContainer}>
                    <span className="">
                        &copy; {new Date().getFullYear()} {labels?.footer?.copyRight?.text1}
                    </span>
                    {screenSize?.isDesktop && <span>{` - `} </span>}
                    <span className="">{labels?.footer?.copyRight?.text2}</span>
                </div>

                {/* Brand logos */}
                {brandLinks?.length && (
                    <ListComponent
                        gaEventClass="event_external_link"
                        linksCollection={brandLinks}
                        variant={"brandLinks"}
                    />
                )}
            </div>
        </footer>
    )
}

Footer.propTypes = {
    note: PropTypes.string,
    mainLinksCollection: PropTypes.object,
    socialLinksCollection: PropTypes.object,
    brandLinksCollection: PropTypes.object,
}

export default Footer
