import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import * as styles from "./Section-tw-styles"
import AdvancedSlideshowContainer from "../AdvancedSlideshowContainer/AdvancedSlideshowContainer"
import RichTextModel from "../RichTextModel/RichTextModel"
import TwoColumnSection from "../TwoColumnSection/TwoColumnSection"
import { toCamelCase } from "../../helpers/utils"
import Image from "../UI/Image/Image"
import Link from "../UI/Link/Link"

const Section = props => {
    const { backgroundColor, variation, contentCollection, firstSection } = props

    const getWindowWidth = () => {
        return typeof window !== "undefined" ? window.innerWidth : 0
    }

    const [windowWidth, setWindowWidth] = useState(getWindowWidth())

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(getWindowWidth())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const bgColor = toCamelCase(backgroundColor)

    const variantStyle = variation ? toCamelCase(variation) : "default"

    const style = styles.defaultStyle?.[variantStyle]

    let sectionAlign = "items-center"
    let minHeight = " sm:min-h-[390px] pt-[30px] pb-[60px] sm:py-[60px]"

    contentCollection.items.forEach(item => {
        if (item.__typename === "AdvancedSlideshowContainer") {
            sectionAlign = ""
            minHeight = ""
        }
    })

    if (contentCollection?.items.length === 0) {
        sectionAlign = "items-center"
        minHeight = "md:min-h-[350px] min-h-[450px] pt-[60px] pb-[60px] sm:py-[60px]"
    }

    const wrapperStyle = `${style.section} ${style.backgroundColor[bgColor]} ${firstSection ? style?.firstSection : ""}`

    const sectionRef = useRef(null)

    const [isInSectionViewport, setIsInSectionViewport] = useState(false)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.2,
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsInSectionViewport(true)
                    observer.disconnect()
                }
            })
        }, options)

        if (sectionRef.current) {
            observer.observe(sectionRef.current)
        }

        // Cleanup function
        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (variantStyle === "parallax") {
            const handleScroll = () => {
                if (sectionRef.current) {
                    const scrollTop = window.pageYOffset
                    const parallaxBackground = sectionRef.current.querySelector(".parallax-background")
                    parallaxBackground.style.transform = `translateY(${scrollTop * 0.5}px)` // Adjust the factor as needed
                }
            }

            window.addEventListener("scroll", handleScroll)

            return () => {
                window.removeEventListener("scroll", handleScroll)
            }
        }
        if (variantStyle === "dots") {
            const handleScroll = () => {
                if (sectionRef.current) {
                    const scrollTop = window.pageYOffset
                    const parallaxBackground = sectionRef.current.querySelector(".section-dots-bg")
                    parallaxBackground.style.transform = `translateX(-${scrollTop * 0.1 - 120}px)` // Adjust the factor as needed
                }
            }

            window.addEventListener("scroll", handleScroll)

            return () => {
                window.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    return (
        <div className={`${wrapperStyle}`} ref={sectionRef}>
            {variantStyle === "parallax" && <div className="parallax-background"></div>}
            {variantStyle === "dots" && (
                <div className="parallax-bg-wrapper">
                    <div className="section-dots-bg"></div>
                </div>
            )}
            <div
                className={`${style?.wrapperDiv} ${
                    variantStyle === "animated" && isInSectionViewport ? "section-animation" : ""
                }`}
            >
                <div className={`${style.sectionContainer} ${sectionAlign} ${minHeight}`}>
                    {Array.isArray(contentCollection?.items) && contentCollection?.items?.length > 0 && (
                        <div className={style.sectionMainContainer}>
                            {contentCollection?.items.map((item, index) => {
                                switch (item.__typename) {
                                    case "MediaElement":
                                        return (
                                            <div className={style?.mediaImageContainer}>
                                                {windowWidth > 425 || windowWidth === 0 ? (
                                                    <Image
                                                        className={style?.mediaImage}
                                                        contentfulImage={item?.media}
                                                    />
                                                ) : (
                                                    <Image
                                                        className={style?.mediaImage}
                                                        contentfulImage={item?.mobileMedia}
                                                    />
                                                )}
                                            </div>
                                        )
                                    case "AdvancedSlideshowContainer":
                                        return (
                                            <AdvancedSlideshowContainer
                                                key={`slideshow-${index}`}
                                                {...item}
                                                sectionBackgroung={bgColor}
                                            />
                                        )
                                    case "TwoColumnSection":
                                        return (
                                            <TwoColumnSection
                                                key={`twoColumnSection-${index}`}
                                                {...item}
                                                sectionBackgroung={bgColor}
                                            />
                                        )
                                    case "RichTextModel":
                                        return (
                                            <div key={`richText-${index}`} className={style?.sectionMainText}>
                                                <RichTextModel {...item} />
                                            </div>
                                        )
                                    default:
                                        return null
                                }
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Section

Section.propTypes = {
    backgroundColor: PropTypes.string,
    borderBottom: PropTypes.string,
    variation: PropTypes.string,
    contentCollection: PropTypes.object,
    firstSection: PropTypes.bool,
}
