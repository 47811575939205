export const defaultStyle = {
    firstSection: "mt-[-185px] pt-[160px]",
    twoColumn: "two-column relative",
    twoColumnWrapper: "m-auto flex justify-around px-[60px] md:px-0 sm:flex-wrap",
    twoColumnFirst: "flex items-center justify-center w-[49%] sm:max-w-full lg:w-full",
    twoColumnSecond: "flex items-center justify-center w-[49%] sm:max-w-full lg:w-full",
    twoColumnImage: "flex justify-center m-auto sm:p-[20px]",
    twoColumnVideo: "flex justify-center m-auto",
    twoColumnText: "relative flex justify-center",
    narrowLeftStyle:
        "h-full mr-[100px] pr-[40px] after:absolute after:right-0 after:top-0 after:w-[6px] after:h-full after:content-[''] after:block after:rounded-[3px] after:bg-fonziRed",
    narrowRightStyle:
        "h-full ml-[100px] pl-[40px] after:absolute after:left-0 after:top-0 after:w-[6px] after:h-full after:content-[''] after:block after:rounded-[3px] after:bg-fonziRed",
    behindTextSvg: "absolute z-[1] top-[-65px] sm:top-[45px] max-w-[360px] max-h-[500px] sm:max-h-[385px]",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
    sectionScrollBox:
        "flex flex-wrap justify-center items-center relative p-[30px] pb-[120px] l:pb-[60px] mt-[-100px] l:mt-[30px] min-h-[90px]",
    sectionScrollBoxWrapper: "scroll-button-animation cursor-pointer flex justify-center w-[100px]",
    sectionScrollSvg: "absolute top-0 z-[2]",
    scrollWrapper: "flex flex-wrap justify-center items-center z-[3]",
    scrollText: "z-[3] w-full text-center mt-[-10px] pb-[20px] uppercase text-white",
    downSvg: "z-[3]",
}

export const homepageFull = {
    firstSection: "mt-[-185px] pt-[230px]",
    twoColumn: "two-column relative flex-wrap h-[950px] l:h-[620px] sm:h-full flex justify-center items-center",
    twoColumnWrapper: "m-auto flex justify-around sm:flex-wrap",
    twoColumnFirst: "flex flex-wrap items-center max-w-[49%] sm:max-w-full sm:w-full",
    twoColumnSecond: "flex flex-wrap items-center max-w-[49%] sm:max-w-full sm:w-full",
    twoColumnImage: "flex justify-center m-auto",
    twoColumnVideo: "flex justify-center relative m-auto",
    twoColumnText: "relative max-w-[750px] m-auto flex justify-center mt-[60px]",
    behindTextSvg: "absolute z-[1] top-[-45px] max-w-[300px] max-h-[500px]",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
    sectionScrollBox:
        "lg:hidden right-[20%] laptop:right-[25%] laptop w-full flex flex-wrap justify-center items-center relative p-[30px] mt-[-100px] min-h-[90px]",
    sectionScrollBoxWrapper: "scroll-button-animation cursor-pointer flex justify-center w-[100px]",
    sectionScrollSvg: "absolute top-0 z-[2]",
    scrollWrapper: "flex flex-wrap justify-center items-center  z-[3]",
    scrollText: "z-[3] w-full text-center mt-[-10px] pb-[20px] uppercase text-white",
    downSvg: "z-[3]",
    playButtonOverlay:
        "absolute top-[calc(50%-30px)] left-[calc(50%-30px)] w-[60px] h-[60px] bg-fonziRed rounded-[60px] flex justify-center items-center",
    playButtonSvg: "w-[60px] h-[60px]",
}

export const homepageNarrow = {
    firstSection: "mt-[-185px] pt-[230px]",
    twoColumn: "two-column relative w-full",
    twoColumnWrapper: "m-auto flex justify-around px-[60px] md:px-[40px] sm:px-0 sm:flex-wrap",
    twoColumnFirst: "flex items-center justify-center sm:max-w-full lg:w-full",
    twoColumnSecond: "flex items-center justify-center sm:max-w-full lg:w-full",
    twoColumnImage: "flex justify-center m-auto sm:p-[20px]",
    twoColumnVideo: "flex justify-center relative m-auto",
    twoColumnText: "relative flex justify-center",
    narrowLeftStyle:
        "h-full pr-[40px] lg:pr-[60px] sm:pr-0 lg:after:hidden after:absolute after:right-0 after:top-0 after:w-[6px] after:h-[80%] after:top-[10%] after:content-[''] after:block after:rounded-[3px] after:bg-fonziRed",
    narrowRightStyle:
        "h-full pl-[40px] lg:pl-[60px] sm:pl-0 lg:after:hidden after:absolute after:left-0 after:top-0 after:w-[6px] after:h-[80%] after:top-[10%] after:content-[''] after:block after:rounded-[3px] after:bg-fonziRed",
    behindTextSvg: "absolute z-[1] top-[-65px] sm:top-[45px] max-w-[360px] max-h-[500px] sm:max-h-[385px]",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
    sectionScrollBox:
        "cursor-pointer flex flex-wrap justify-center items-center relative p-[30px] pb-[120px] md:pb-[60px] mt-[-100px] md:mt-[30px] min-h-[90px]",
    sectionScrollSvg: "absolute top-0 z-[2]",
    scrollWrapper: "flex flex-wrap justify-center items-center z-[3]",
    scrollText: "z-[3] w-full text-center mt-[-10px] pb-[20px] uppercase text-white",
    downSvg: "z-[3]",
    firstColHasMedia: "has-image w-[60%] l:w-[50%] sm:w-full",
    firstColHasNoMedia: "w-[39%] text-right sm:text-center l:w-[35%] sm:w-full",
    secondColHasMedia: "has-image w-[60%] l:w-[50%] sm:w-full",
    secondColHasNoMedia: "w-[39%] text-left sm:text-center l:w-[35%] sm:w-full",
    playButtonOverlay:
        "absolute top-[calc(50%-30px)] left-[calc(50%-30px)] w-[60px] h-[60px] bg-fonziRed rounded-[60px] flex justify-center items-center",
    playButtonSvg: "w-[60px] h-[60px]",
}
