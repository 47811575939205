export const defaultStyle = {
    Listcontainer: "Listcontainer w-full lg:w-auto lg:inline-block px-10",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "py-20",
    className2: "lg:inline-block lg:w-auto w-full",
    className3: "text-[0.70rem] proximaNova-Regular,serif text-slate-50 px-2",
    className4:
        "text-[0.70rem] proximaNova-Regular,serif lg:before:content-['/'] before:pr-4 before:text-blue-500 text-slate-50 px-2 ",
}

export const footerMainLinks = {
    Listcontainer:
        "Listcontainer lg:w-full footer-link-style uppercase mr-[10px] lg:mr-0 lg:mb-[12px] font-HelveticaNeueBold text-[12px] lg:text-[16px]",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "pb-25 flex lg:flex-wrap",
    listLink: "",
}

export const socialLinks = {
    Listcontainer: "Listcontainer w-full lg:w-auto lg:inline-block px-10",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "py-20",
    className2: "lg:inline-block lg:w-auto w-full",
    className3: "text-[0.70rem] proximaNova-Regular,serif text-slate-50 px-2",
    className4:
        "text-[0.70rem] proximaNova-Regular,serif lg:before:content-['/'] before:pr-4 before:text-blue-500 text-slate-50 px-2 ",
}

export const brandLinks = {
    Listcontainer: "Listcontainer w-full flex justify-center m-auto lg:w-[175px] lg:inline-block mx-auto my-0",
    footerlinsMob: "text-center mx-[40px]",
    listmob: "w-full",
}
