const query = isPreview => `{
  headerCollection(preview: ${isPreview}, limit: 1) {
    items {
      logo {
        url
        title
        description
        width
        height
      }
      navigationCollection(limit: 10) {
        items {
          label
          link {
            label
            title
            url
            target
            scrollToElementId
            image {
              title
              description
              url
              width
              height
            }
          }
        }
      }
      socialLinksCollection(limit: 10) {
        items {
          ... on Link {
            label
            title
            url
            target
            scrollToElementId
            image {
              title
              description
              url
              width
              height
            }
          }
        }
      }
    }
  }
}
`

module.exports = { query }
