import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Image from "../../UI/Image/Image"
import Link from "../../UI/Link/Link"
import { get } from "../../../helpers/utils"
import useScreenSize from "../../../hooks/useScreenSize"
import NavigationDesktop from "./Navigation/Desktop/Navigation"
import NavigationMobile from "./Navigation/Mobile/Navigation"
import { gaEventClasses, gaEventLabels } from "../../../constants/gtm.constants"
import * as styles from "./Header-tw-styles"
import { useGlobalContext } from "../../../context/GlobalContext"

const Header = props => {
    const { logo, navigationCollection, socialLinksCollection } = props
    const labels = useGlobalContext().labels
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

    const [navCloseAnimation, setNavCloseAnimation] = useState(true)

    const screen = useScreenSize()
    const navigationData = get(navigationCollection, "items", [])
    const socialLinksData = get(socialLinksCollection, "items", [])

    const style = styles.defaultStyle
    const {
        headerContainer,
        headerContent,
        headerLogo,
        logoImg,
        navigationContainer,
        socialIconContainer,
        socialIcon,
        socialSvg,
    } = style

    const handleScrollTopClick = event => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }
    // Disable scrolling
    function disableScroll() {
        document.documentElement.style.overflow = "hidden"
    }

    // Enable scrolling
    function enableScroll() {
        document.documentElement.style.overflow = "auto"
    }

    const toggleMobileNav = () => {
        if (isMobileNavOpen) {
            setNavCloseAnimation(true)
            const delay = 1000
            setTimeout(() => {
                setIsMobileNavOpen(false)
            }, delay)
        } else {
            setIsMobileNavOpen(true)
            const delay = 1000
            setTimeout(() => {
                setNavCloseAnimation(false)
            }, delay)
        }
    }

    useEffect(() => {
        if (isMobileNavOpen) {
            handleScrollTopClick()
            disableScroll()
        } else {
            enableScroll()
        }
    }, [isMobileNavOpen])

    return (
        <header className={headerContainer}>
            <div className={headerContent}>
                <div className={headerLogo}>
                    <Link url={labels?.urls?.homepage} title={labels?.header?.homepage}>
                        <Image
                            className={logoImg}
                            contentfulImage={logo}
                            width={labels?.header?.logo?.width}
                            height={labels?.header?.logo?.height}
                            eventClass={gaEventClasses.logo}
                            eventLabel={gaEventLabels.logo}
                        />
                    </Link>
                </div>
                <div className={navigationContainer}>
                    {screen?.isMobile || screen?.isTablet ? (
                        <NavigationMobile
                            navigation={navigationData}
                            isMobileNavOpen={isMobileNavOpen}
                            toggleMobileNav={toggleMobileNav}
                            socialLinksData={socialLinksData}
                            navCloseAnimation={navCloseAnimation}
                            setNavCloseAnimation={setNavCloseAnimation}
                        />
                    ) : (
                        <NavigationDesktop navigation={navigationData} />
                    )}
                    {socialLinksData && !screen?.isTablet && !screen?.isMobile && (
                        <div className={socialIconContainer}>
                            {socialLinksData.map((item, index) => {
                                return (
                                    <div className={socialIcon} key={index}>
                                        <Link url={item?.url} gaEventClass={gaEventClasses.socialmedia_exit}>
                                            <Image className={socialSvg} contentfulImage={item?.image} />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    logo: PropTypes.object,
    navigationCollection: PropTypes.object,
    socialLinksCollection: PropTypes.object,
}

export default Header
