import React, { useEffect, useState } from "react"
import Link from "../../../../../UI/Link/Link"
import PropTypes from "prop-types"
import * as styles from "./NavigationItem-tw-styles"
import { deslashify } from "../../../../../../helpers/utils"
import { gaEventClasses } from "../../../../../../constants/gtm.constants"

const NavigationItem = props => {
    const { item } = props
    const { label, link, variant } = item
    const style = styles[variant] || styles.defaultStyle
    const { navLink } = style

    const [activeLinkClass, setActiveLinkClass] = useState("")

    useEffect(() => {
        let currentPath = ""
        if (typeof window !== "undefined") {
            currentPath = window?.location?.pathname
            const isActive = deslashify(currentPath) === deslashify(link?.url)
            if (isActive) {
                setActiveLinkClass("desktop-nav-active")
            }
        }
    }, [])

    return (
        <li className="desk-nav-item px-[30px] lg:px-[25px]">
            {link ? (
                <Link
                    {...link}
                    className={`${navLink} ${activeLinkClass}`}
                    gaEventClass={gaEventClasses.menu_click}
                    gaEventLabel={label}
                >
                    {label}
                </Link>
            ) : (
                label
            )}
        </li>
    )
}

NavigationItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.object,
        variant: PropTypes.string,
    }),
}

export default NavigationItem
