export const defaultStyle = {
    headerContainer:
        "headerContainer lg:z-[666] relative py-[30px] px-[60px] l:py-[20px] l:px-[30px] h-[185px] l:h-[125px] flex justify-between w-full",
    headerContent: "headerContent mid:fixed flex w-full justify-between items-center",
    headerLogo: "headerLogo fixed top-[30px] l:top-0 l:relative w-[90px] z-[666] l:w-[62px] h-[125px] l:h-[86px]",
    brandLogo: "",
    logoImg: "max-h-full",
    logoWraperLink: "logoWraperLink",
    navigationContainer:
        "navigation-container relative z-[666] ml-auto l:mr-[0px] mid:border-none mid:bg-transparent flex items-center h-[48px] rounded-full border border-fonziRed text-fonziRed bg-fonziYellow px-5 md:pr-0 pointer-events-auto",
    socialIconContainer: "flex items-center",
    socialIcon:
        "header-social-icon cursor-pointer flex justify-center items-center w-[40px] h-[40px] p-[0.5em] mx-[4px] rounded-[50%]",
    socialSvg: "w-[18px]",
}
