import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
//import YouTube from "react-youtube"
import * as styles from "./SlideshowItemYoutube-tw-styles"
import { gaEventClasses } from "../../../constants/gtm.constants"

const SlideshowItemYoutube = ({ videoId, windowWidth }) => {
    const style = styles.defaultStyle

    const [player, setPlayer] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const thumbnailRef = useRef(null)

    const togglePlayback = () => {
        if (player) {
            if (isPlaying) {
                player.pauseVideo()
            } else {
                player.playVideo()
            }
            setIsPlaying(!isPlaying)
        }
    }

    const onReady = event => {
        setPlayer(event.target)
    }

    const onStop = () => {
        setIsPlaying(false)
        thumbnailRef.current.style.display = "block"
    }

    let videoStyle = { width: "550", height: "310" }

    switch (true) {
        case windowWidth <= 1440 && windowWidth > 1180:
            videoStyle = { width: "400", height: "225" }
            break
        case windowWidth <= 1180:
            videoStyle = { width: "310", height: "180" }
            break
        default:
            break
    }

    return (
        <div className={style?.youtubeSlideContainer}>
            <div
                ref={thumbnailRef}
                className={style?.thumbnailContainer}
                onClick={togglePlayback}
                style={{ display: isPlaying ? "none" : "block" }}
            >
                <img
                    className={style?.thumbnailImage}
                    src={`https://img.youtube-nocookie.com/vi/${videoId}/hqdefault.jpg`}
                    alt="Video Thumbnail"
                />
                <div
                    className={`${style?.playButtonOverlay} ${gaEventClasses.video_play}`}
                    data-action-detail="Video Thumbnail"
                >
                    <svg
                        className={style?.playButtonSvg}
                        id="icon-play-only"
                        viewBox="0 0 71 71"
                        enableBackground="new 0 0 71 71"
                        preserveAspectRatio="xMidYMid meet"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M45.5,35.5l-16,12v-24Z" fill="#fff" fillRule="evenodd" />
                    </svg>
                </div>
            </div>
            {/* <YouTube
                videoId={videoId}
                opts={{
                    width: videoStyle.width,
                    height: videoStyle.height,
                    playerVars: {
                        autoplay: 0,
                        controls: 1,
                    },
                }}
                onReady={onReady}
                onPlay={() => (thumbnailRef.current.style.display = "none")}
                onPause={onStop}
                onEnd={onStop}
            />*/}
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${videoId}`}
              frameBorder="0"
              width={videoStyle.width}
              height={videoStyle.height}
              allowFullScreen
              title={"YouTube video player"}
              allow={"autoplay; encrypted-media"}
              id={videoId}
              className={[style?.videoItem, "event_video_play"].join(" ")}
              data-action-detail={`https://www.youtube-nocookie.com/embed/${videoId}`}
              csp="*.youtube.com"
            />
        </div>
    )
}

export default SlideshowItemYoutube

SlideshowItemYoutube.propTypes = {
    videoId: PropTypes.string,
    windowWidth: PropTypes.number,
}
