export const defaultStyle = {
    aboutSection: "relative about-section py-[60px]",
    aboutSectionTopText: "about-section-text z-[3] relative max-w-[480px] m-auto ",
    aboutSectionCardContainer: "z-[3] relative flex flex-wrap",
    backgroundColor: {
        white: "bg-white",
        red: "bg-fonziRed",
        yellow: "bg-fonziYellow",
    },
}
